import { Col, Container, Row } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import { BREAKPOINTS } from "../../utils/Constants";
import useCountry from "../../hooks/config/useConfig";

import { institutionalLinks } from "../../data/countriesFeatures";
import { useEffect, useState } from "react";
import { FooterDataType } from "../../interfaces/footer";
import { Institutional } from "../../interfaces/config";
import {getFooterInfo} from "../../data/footer"
import { useLinks } from "../../hooks/config/useLinks";

export const Footer = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { country ,allCountries,countryFeatures  } = useCountry();
  const { mainProjectsUrlByCountry } = useLinks()
  const [footerInfo, setFooterInfo] = useState<FooterDataType>();
  const [institutional, setInstitutional] = useState<Institutional[]>([]);

  console.log({mainProjectsUrlByCountry})
  useEffect(() => {
    if (country !== ""&&allCountries && allCountries.length > 0) {
      let footerData = getFooterInfo(country,allCountries,countryFeatures,mainProjectsUrlByCountry)
      setFooterInfo(footerData)
      setInstitutional(institutionalLinks[country])
    }
  }, [country,allCountries,countryFeatures,mainProjectsUrlByCountry])

  return (
    <footer className={"bg-secondary text-light d-block pt-5 " + (breakpoint === "xs" ? "px-4" : "")}>
      <Container className={(breakpoint === "xs" ? "px-0" : "")}>
        <Row className="pb-4 gx-2 gy-5 justify-content-center">
          {footerInfo?.top.map((col, index) => (
            <Col lg="3" md="4" sm="6" key={`col-footer-${index}`}>
              <h6 className="mb-3 fw-bold">{col.title}</h6>
              {col.content.map((cont, childrenIndex) => (
                <a
                  key={`content-col-footer-${childrenIndex}`}
                  href={cont.link}
                  className="lh-lg d-block"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <small className="text-light text-decoration-none">
                    {('icon' in cont) &&
                      <i className={
                        "me-2 bi " +
                        (cont.icon === "phone" ? "bi-telephone-fill" : cont.icon === "whatsapp" ? "bi-whatsapp" : "bi-envelope-fill")
                      }></i>
                    }
                    {cont.text}
                  </small>
                </a>
              ))}
            </Col>
          ))}

          {!!institutional?.length &&
            <Col lg="3" md="4" sm="6">
              <h6 className="mb-3 fw-bold">Institucional</h6>

              {institutional.map((link, childrenIndex) => (
                <a
                  key={`content-col-footer-${childrenIndex}`}
                  href={link.link}
                  className="lh-lg d-block"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <small className="text-light text-decoration-none">
                    {link.text}
                  </small>
                </a>
              ))}

            </Col>
          }
        </Row>

        <hr className="text-white" />

        <Row className="py-4 gx-2 gy-1">
          <Col lg="2" className="mb-3">
            <div className={"d-block " + (country === "CL" || country === "PA" ? "  align-items-center" : ( country == 'CO' ?  'align-items-end' :"align-items-baseline"))}>
              <span className="text-light me-2">{footerInfo?.bottom.left.text}</span>
              <CustomImage
                alt="InfoCasas"
                src={footerInfo ? footerInfo.bottom.left.image : ""}
                width={100}
                height={100}
                styles={{ maxWidth: country === "PA" ? 180 : 120 }}
                classes={country === "CL" ? "mt-1" : ""}
              />
            </div>
          </Col>

          {footerInfo?.bottom.right.map((content, index) => (
            <Col className="text-center" lg="2" md="3" key={`footer-right-${index}`}>
              <a href={content.link} className="lh-lg" target={"_blank"} rel="noreferrer">
                <small className="text-light text-decoration-none">
                  {content.text}
                </small>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </footer>
  );
};
