import dayjs from "dayjs";
import { useReducer, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { sendEmail, sendFormulario } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { UtmArgs } from "../../interfaces/email";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType } from "../../interfaces/project";
import { useUserFetch } from "../../hooks/fetch/useUserFetch"
import { ClientForm } from "./ClientRegister/ClientForm";
import { UserClient, UserClientsBody } from "../../interfaces/userActivity";


export interface ClientRegisterFormType {
  clients: UserClient[]
}

const defaultScheduleForm: ClientRegisterFormType = {
  clients: [{
    name: '',
    lastname: '',
    email: '',
    phone: ''
  }]
};

type ClientRegisterFormAction =
  | { type: "set"; payload: ClientRegisterFormType }
  | {
    type: "change";
    payload: {
      input: "type" | "date" | "time" | "message" | "client_name" | "hours" | "clients_0_name" | "clients_0_lastname"
      | "clients_0_email" | "clients_0_phone" | "clients_1_name" | "clients_1_lastname" | "clients_1_email" | "clients_1_phone"
      | "clients";
      value: any;
    };
  }
  | { type: "reset" };

const reducer = (
  state: ClientRegisterFormType,
  action: ClientRegisterFormAction
): ClientRegisterFormType => {
  if (action.type === "set") return action.payload;
  else if (action.type === "change") {
    let res: any = { ...state };
    if (action.payload.input.includes("clients_")) {
      const [input, index, attribute] = action.payload.input.split('_')
      res[input][index][attribute] = action.payload.value;
    } else {
      res[action.payload.input] = action.payload.value;
    }

    return res;
  } else return defaultScheduleForm;
};

export const ClientRegister = ({ property }: { property: ProjectType }) => {
  const { userState } = useUser();
  const [searchParams] = useSearchParams();
  const [form, setForm] = useReducer(reducer, defaultScheduleForm);
  const [send, setSend] = useState<null | "sending" | "error" | "sended">(null);
  const [showError, setShowError] = useState(false);
  const GA = useGoogleAnalytics(true);
  const { country } = useCountry()
  const { registerVisit,registerClients } = useUserFetch()
  const [userPhone, setUserPhone] = useState('')
  const { updateProfile } = useUserFetch()
  const [validated, setValidated] = useState(false)
  const [ hasTwoClients, setHasTwoClients] = useState(false)

  const defaultClientErrors = {
    name : 'Campo requerido' , lastname : 'Campo requerido', email : 'Campo requerido' , phone : 'Campo requerido'
  }
  const defaultErrorMessages = {
    clients : [defaultClientErrors]
  }

  const [errorMessages, setErrorMessages] = useState(defaultErrorMessages)


  const validate = (): any[] => {

    let results = form.clients.map((client,index)  => {

      let errors = []
      
      if(!client.name || client.name.trim() == ""){
        errors.push({
          status: 'error',
          message: 'Campo requerido',
          client_field : 'name',
          field: 'formClientName_'+index,
          index 
        })
      }

      if(!client.lastname || client.lastname.trim() == ""){
        errors.push({
          status: 'error',
          message: 'Campo requerido',
          client_field : 'lastname',
          field: 'formClientLastname_'+index,
          index 
        })
      }

      if(!client.email || client.email.trim() == ""){
        errors.push({
          status: 'error',
          message: 'Campo requerido',
          client_field : 'email',
          field: 'formClientEmail_'+index,
          index 
        })
      }else if(!client.email.match(/^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z]{2,4}$/i)) {
        errors.push({
          status: 'error',
          message: 'Email inválido',
          client_field : 'email',
          field: 'formClientEmail_'+index,
          index 
        })
      }

      if(!client.phone || client.phone.trim() == ""){
        errors.push({
          status: 'error',
          message: 'Campo requerido',
          client_field : 'phone',
          field: 'formClientPhone_'+index,
          index 
        })
      }else if(!client.phone.trim().match(/^\d+$/)) {
        errors.push({
          status: 'error',
          message: 'Teléfono inválido: solo números',
          client_field : 'phone',
          field: 'formClientPhone_'+index,
          index 
        })
      }else if(client.phone.length < 6) {
        errors.push({
          status: 'error',
          message: 'Mínimo 6 dígitos',
          client_field : 'phone',
          field:'formClientPhone_'+index,
          index 
        })
      }
      return {
        hasErrors : errors.length > 0 ? true : false,
        errors
      }

    })

    return results
  };

  const handleSwitch = (ev :any) => {
    const value = !!ev.target.checked
    setHasTwoClients(value)
    let setClients = [...form.clients]
    let errorClients = [...errorMessages.clients]
    if(value){
      const newClient : UserClient= {
        name : '',
        lastname : '',
        phone : '',
        email : ''
      }
      setClients = [...form.clients,newClient]
      errorClients = [...errorClients, defaultClientErrors]
    }else{
      setClients = [form.clients[0]]
      errorClients = [errorMessages.clients[0]]
    }
    setForm({ type: "change", payload: { input: `clients`, value: setClients } })
    setErrorMessages(prev  => ({...prev,clients : errorClients}))
  }

  const onSend = (e: any) => {
    e.preventDefault();

    let _form = e.currentTarget;
    errorMessages.clients.map((client,index) => {
      Object.keys(client).forEach(key => {
        const field =  key.charAt(0).toUpperCase() + key.slice(1);
        _form[`formClient${field}_${index}`]?.setCustomValidity('')
      })
    })
    

    setValidated(true);
    let validForm = _form.checkValidity()
    console.log("_form ", _form)
    const validateForm = validate()
    const isValid = !validateForm.find((v:any) => v.hasErrors)

    if (!isValid || !validForm) {
      console.log("error", validForm)
      setSend("error");
      if(!isValid){

        const clientsWithError = validateForm.filter( v => v.hasErrors)
        console.log("errorClients ",clientsWithError)
        clientsWithError.map((client) => {

          client.errors.map( (ec:any) => {
            setErrorMessages((prev : any) => {
              return {
                ...prev,
                clients : [
                  ...prev.clients.slice(0, ec.index), 
                  {
                    ...prev.clients[ec.index],
                    [ec.client_field] : ec.message
                  }, 
                  ...prev.clients.slice(ec.index + 1) 
                ]
              }
            })
  
            _form[ec.field]?.setCustomValidity('invalid')
          })

         

        })
        
      }
    }
    else {

      const idForm = process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test" ? 480 : 1830
      const idPais = 1
      const formFormat: any = form;

      formFormat.form_type = "client_register";
      formFormat['id_project'] = property.identifier;
      formFormat['title_project'] = property.title;
      formFormat['project_country'] = property.country
      formFormat['id_inmo'] = userState.id;
      formFormat['name_inmo'] = `${userState.name} - ${userState.real_estate}`
      formFormat['email_inmo'] = userState.email;
      formFormat['phone_inmo'] = userState.phone || userPhone;
      formFormat['countryCode'] = country

      const formData = {
        IDuser: parseInt(userState.id),
        nombre: userState.name,
        email: userState.email,
        extra: JSON.stringify(form.clients),
        fecha: dayjs().format("YYYY-MM-DD"),
      }

      const utms: UtmArgs = {
        utm_content: `${property.title}-${property.identifier}`,
        utm_source: searchParams.get("utm_source") != null ? searchParams.get("utm_source") : undefined,
        utm_medium: searchParams.get("utm_medium") != null ? searchParams.get("utm_medium") : undefined,
        utm_campaign: searchParams.get("utm_campaign") != null ? searchParams.get("utm_campaign") : undefined,
      }

      setSend("sending");
      
      sendEmail(formFormat)
        .then((res: any) => {
          if (res.success) {
            setSend("sended");
          }
          else {
            setSend("error")
            setShowError(true)
          };
        })
        .finally(() => {
          GA.Event({ category: "User register client", action: "user_register_client", label: `${userState.name}`, value: Number(userState.id) })
        })

      sendFormulario(formData, idForm, idPais, utms)
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
      
      const clientsBody : UserClientsBody = {
          project_id : property.id,
          project_name : property.title,
          clients : form.clients
      }
  
      registerClients(clientsBody)

    }
  };



  return (
    <Form
      className="form-schedule-visit position-relative"
      noValidate
      validated={send === "sended" || send === "error"}
      onSubmit={onSend}
    >

      <Form.Group>
        <Form.Check // prettier-ignore
          type="switch"
          
          id="custom-switch"
          label="Registrar 2 clientes"
          checked={hasTwoClients}
          onChange={handleSwitch}
          className="mt-3 fs-sm"
        />
      </Form.Group>

      {
        form.clients.length>0 &&
        form.clients.map((client,index) => <>
          <ClientForm 
            key={index} 
            index={index} 
            form={form}
            setForm={setForm} 
            send={send || ''} 
            errorMessages={errorMessages}
            requiredOtherThanName={true} />
        </>)
      }

      
      <Button
        variant={send === "sended" ? "success" : "primary"}
        type="submit"
        className="send-button w-100"

        disabled={send === "sending" || send === "sended"}
      >
        {send === "sending" ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
        ) : null}
        {send === "sending"
          ? "Registrando"
          : send === "sended"
            ? "Registrado"
            : "Registrar"}
      </Button>

      <Alert variant="danger" show={showError}>
        <Button className="alert-btn" onClick={() => setShowError(false)}>
          <i className="bi bi-x"></i>
        </Button>
        <p>
          No pudimos enviar tu mensaje. <br />
          Por favor, intenta nuevamente.
        </p>
      </Alert>
    </Form>
  );
};
