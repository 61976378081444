import { Button, Dropdown } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import { useEffect, useState } from "react";
import useUser from "../../hooks/user/useUser";
import { crypt } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import { IconLink } from "../CustomIcons/IconLink";
import { ProjectType } from "../../interfaces/project";

export const PropertyLinkShare = ({ property, operation }: {
  property: ProjectType, operation?: OperationType
}) => {
  const { userState } = useUser();

  const [link, setLink] = useState('')
  const [text, setText] = useState('')
  const [pureLink, setPureLink] = useState('')
  const [showCopied, setShowCopied] = useState(false)

  const GA = useGoogleAnalytics()

  useEffect(() => {
    const location = window.location
    let encryptedId = crypt('hashId', 'inmo-' + userState.id.toString())
    let queryOperation = operation ? '?operation=' + operation : ''
    let url = location.protocol + '//' + location.hostname + "/inmobiliaria/" + encryptedId + "/proyecto/" + property.identifier + queryOperation
    //url = 'https://iris.infocasas.com.uy/proyecto/23'
    setPureLink(url)
    setLink(encodeURIComponent(url))
    setText("Mira este proyecto")
  }, [])


  const copyLink = (e: any) => {
    e.preventDefault()
    navigator.clipboard.writeText(pureLink);
    setShowCopied(true)
    setTimeout(() => {
      setShowCopied(false)
    }, 2000);
  }

  const sendEvent = () => {
    GA.Event({ category: "Project shared as link", action: "project_shared_as_link", label: `${userState?.name}`, value: Number(userState?.id) })
  }


  return (
    <>
      <Dropdown className="position-relative">

        <Dropdown.Toggle className="toggle-share p-0" variant="">

          <div
            id="btnShareLink"
            className="property-share fw-normal d-flex align-items-center 
          text-secondary lh-1 p-0"
            onClick={sendEvent}
          >
            <IconLink width={28} height={28} className="text-primary" />
            <span className="d-none d-lg-inline ms-2">Compartir Link</span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="menu-share">

            <a href="" className="share-copy position-relative d-flex justify-content-center align-items-center" onClick={(e) => copyLink(e)}>Copiar link
              <i className={`bi text-primary fs-5 bi-link-45deg `}></i>
              {showCopied && <span className="position-absolute" >¡Copiado!</span>}
            </a>

            <div
              className="d-flex justify-content-center">

              <a href={`https://twitter.com/share?url=${link}&text=${text}`} target="_blank"
                title="Twitter">
                <i className={`bi text-primary fs-5 bi-twitter twitter`}></i>
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
                target="_blank" title="Facebook">
                <i className={`bi text-primary fs-5 bi-facebook facebook`}></i>
              </a>

              <a href={`https://wa.me/?text=${link}`} target="_blank" title="Whatsapp">
                <i className={`bi text-primary fs-5 bi-whatsapp whatsapp`}></i>
              </a>
              <a href={`https://www.linkedin.com/shareArticle?url=${link}&title=${property.title}&summary=${text}`}
                target="_blank" title="LinkedIn">
                <i className={`bi text-primary fs-5 bi-linkedin linkedin`}></i>
              </a>
            </div>
          </div>

        </Dropdown.Menu>

      </Dropdown>

    </>

  );
};
