import dayjs from "dayjs";
import { useReducer, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { sendEmail, sendFormulario } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { UtmArgs } from "../../interfaces/email";
import { DateCalendar, DATE_FORMAT } from "./DateCalendar";
import { ToggleButtons } from "./ToggleButtons";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType } from "../../interfaces/project";
import { useUserFetch } from "../../hooks/fetch/useUserFetch"
import { UpdateUserBody, UserClient, UserVisitBody } from "../../interfaces/userActivity";
import SpecialSelect, { OptionSelectType } from "../ui/SpecialSelect";
import { ScheduleVisitClient } from "./ScheduleVisitClient";
import { ClientForm } from "./ClientRegister/ClientForm";

interface ScheduleVisitFormType {
  type?: "VIRTUAL" | "PRESENCIAL";
  date?: string;
  time?: "MORNING" | "AFTERNOON";
  message?: string;
  client_name?: string
  hours: OptionSelectType[]
  clients: UserClient[]
}

const defaultScheduleForm: ScheduleVisitFormType = {
  type: "PRESENCIAL",
  date: dayjs().format(DATE_FORMAT),
  time: "MORNING",
  message: "",
  hours: [],
  client_name: '',
  clients: [{
    name: '',
    lastname: '',
    email: '',
    phone: ''
  }]
};

type ScheduleVisitFormAction =
  | { type: "set"; payload: ScheduleVisitFormType }
  | {
    type: "change";
    payload: {
      input: "type" | "date" | "time" | "message" | "client_name" | "hours" | "client_name" | "hours" | "clients_0_name" | "clients_0_lastname"
      | "clients_0_email" | "clients_0_phone" | "clients_1_name" | "clients_1_lastname" | "clients_1_email" | "clients_1_phone"
      | "clients"
      value: any;
    };
  }
  | { type: "reset" };

const reducer = (
  state: ScheduleVisitFormType,
  action: ScheduleVisitFormAction
): ScheduleVisitFormType => {
  if (action.type === "set") return action.payload;
  else if (action.type === "change") {
    let res: any = { ...state };
    if (action.payload.input.includes("clients_")) {
      const [input, index, attribute] = action.payload.input.split('_')
      res[input][index][attribute] = action.payload.value;
    } else {
      res[action.payload.input] = action.payload.value;
    }

    return res;
  } else return defaultScheduleForm;
};

const defaultClientErrors = {
  name: 'Campo requerido', lastname: 'Campo requerido', email: 'Campo requerido', phone: 'Campo requerido'
}
const defaultErrorMessages = {
  clients: [defaultClientErrors]
}

const available_hours = ['Cualquier hora', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'].map(o => ({ label: o, value: o }))
export const ScheduleVisit = ({ property }: { property: ProjectType }) => {
  const { userState } = useUser();
  const [searchParams] = useSearchParams();
  const [form, setForm] = useReducer(reducer, defaultScheduleForm);
  const [send, setSend] = useState<null | "sending" | "error" | "sended">(null);
  const [showError, setShowError] = useState(false);
  const GA = useGoogleAnalytics(true);
  const { country , countryFeatures } = useCountry()
  const { registerVisit } = useUserFetch()
  const [userPhone, setUserPhone] = useState('')
  const { updateProfile } = useUserFetch()
  const [invalidHours, setInvalidHours] = useState(false)
  const [validated, setValidated] = useState(false)
  const [errorMessages, setErrorMessages] = useState(defaultErrorMessages)
  const [hasTwoClients, setHasTwoClients] = useState(false)

  const requirePhone = !userState.phone || userState.phone == '' ? true : false

  const defaultMessage = "Hola, vi esta propiedad en IRIS y quisiera coordinar una visita ¿Pueden confirmarme disponibilidad de día y horario? Gracias."

  const showClientInsideSchedule = countryFeatures.use_some_client_register && 
  ((countryFeatures.use_client_register_inside && !property.countryFeatures?.use_client_register_outside) || property.countryFeatures?.use_client_register_inside)

  const validate = (): boolean => {
    form.message = typeof form.message !== "undefined" && !!form.message?.length ? form.message : defaultMessage
    let validHours = true
    setInvalidHours(false)
    if (country == 'CO') {
      if (form.hours.length < 2 && !form.hours.find(h => h.label == 'Cualquier hora')) {
        setInvalidHours(true)
        validHours = false
      }
    }
    return form.message?.length > 0 && (!requirePhone || (requirePhone && userPhone.length > 0)) && validHours;
  };

  const validateClients = (): any[] => {

    let results = form.clients.map((client, index) => {

      let errors = []

      if (!client.name || client.name.trim() == "") {
        errors.push({
          status: 'error',
          message: 'Campo requerido',
          client_field: 'name',
          field: 'formClientName_' + index,
          index
        })
      }

      if (!client.lastname || client.lastname.trim() == "") {
        errors.push({
          status: 'error',
          message: 'Campo requerido',
          client_field: 'lastname',
          field: 'formClientLastname_' + index,
          index
        })
      }

      if (client.email && !client.email.match(/^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z]{2,4}$/i)) {
        errors.push({
          status: 'error',
          message: 'Email inválido',
          client_field: 'email',
          field: 'formClientEmail_' + index,
          index
        })
      }

      if (client.phone && !client.phone.trim().match(/^\d+$/)) {
        errors.push({
          status: 'error',
          message: 'Teléfono inválido: solo números',
          client_field: 'phone',
          field: 'formClientPhone_' + index,
          index
        })
      } else if (client.phone && client.phone.length < 6) {
        errors.push({
          status: 'error',
          message: 'Mínimo 6 dígitos',
          client_field: 'phone',
          field: 'formClientPhone_' + index,
          index
        })
      }
      return {
        hasErrors: errors.length > 0 ? true : false,
        errors
      }

    })

    return results
  }

  const setValidForm = (_form : any) => {
    setValidated(true);

    errorMessages.clients.map((client,index) => {
      Object.keys(client).forEach(key => {
        const field =  key.charAt(0).toUpperCase() + key.slice(1);
        _form[`formClient${field}_${index}`]?.setCustomValidity('')
      })
    })
  }

  const onSend = (e: any) => {
    e.preventDefault();

    let _form = e.currentTarget;
    setValidForm(_form)

    let validForm = _form.checkValidity()

    if (!validate() || !validForm) {
      setSend("error");
    }
    else {
      const validClients = validateClients()
      const isValidClients = !validClients.find((v: any) => v.hasErrors)

      if ( (showClientInsideSchedule && !isValidClients) || !validForm) {

        setSend("error");
        
        if(!isValidClients){

          const clientsWithError = validClients.filter( v => v.hasErrors)

          clientsWithError.map((client) => {
            client.errors.map( (ec:any) => {
              setErrorMessages((prev : any) => {
                return {
                  ...prev,
                  clients : [
                    ...prev.clients.slice(0, ec.index), 
                    {
                      ...prev.clients[ec.index],
                      [ec.client_field] : ec.message
                    }, 
                    ...prev.clients.slice(ec.index + 1) 
                  ]
                }
              })
    
              _form[ec.field]?.setCustomValidity('invalid')
            })
          })
          
        }

      } else {

        const idForm = process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test" ? 480 : 924
        const idPais = 1
        const formFormat: any = form;
        formFormat.hours = form.hours.map(h => h.label).join(',')
        formFormat.form_type = "squedule";
        formFormat['id_project'] = property.identifier;
        formFormat['title_project'] = property.title;
        formFormat['project_country'] = property.country
        formFormat['id_inmo'] = userState.id;
        formFormat['name_inmo'] = `${userState.name} - ${userState.real_estate}`
        formFormat['email_inmo'] = userState.email;
        formFormat['phone_inmo'] = userState.phone || userPhone;
        formFormat['countryCode'] = country
        const extra = `
        Tipo de visita: ${formFormat.type}.
        Fecha: ${formFormat.date}  
        Horario: ${formFormat.time}
        Mensaje: ${formFormat.message}
      `
        const formData = {
          IDuser: parseInt(userState.id),
          nombre: userState.name,
          email: userState.email,
          extra,
          fecha: dayjs().format("YYYY-MM-DD"),
        }
        const utms: UtmArgs = {
          utm_content: `${property.title}-${property.identifier}`,
          utm_source: searchParams.get("utm_source") != null ? searchParams.get("utm_source") : undefined,
          utm_medium: searchParams.get("utm_medium") != null ? searchParams.get("utm_medium") : undefined,
          utm_campaign: searchParams.get("utm_campaign") != null ? searchParams.get("utm_campaign") : undefined,
        }

        setSend("sending");

        sendEmail(formFormat)
          .then((res: any) => {
            if (res.success) {
              setSend("sended");
            }
            else {
              setSend("error")
              setShowError(true)
            };
          })
          .finally(() => {
            GA.Event({ category: "User squedule visit", action: "user_schedule_visit", label: `${userState.name}`, value: Number(userState.id) })
          })

        sendFormulario(formData, idForm, idPais, utms)
          .then((res) => console.log(res))
          .catch((err) => console.log(err))

        const shift = formFormat.time == 'MORNING' ? 'MAÑANA' : (formFormat.time == 'AFTERNOON' ? 'TARDE' : '')

        const visit: UserVisitBody = {
          project_id: property.id,
          project_name: property.title,
          type: formFormat.type,
          shift: shift,
          date: dayjs(formFormat.date, 'MM-DD-YYYY').format("YYYY-MM-DD"),
          description: formFormat.message,
          available_hours: formFormat.hours,
          client_name: formFormat.client_name
        }

        registerVisit(visit)

        if (requirePhone) {
          updatePhone()
        }



      }


    }
  };

  const updatePhone = () => {

    let userData: UpdateUserBody = {
      ...userState,
      phone: userPhone,
      real_estate: userState.real_estate || '',
      city: userState.city || '',
      primary_color: userState.primary_color || '',
      image: userState.image || '',
      image_file: null,
      delete_image: false,
    }
    updateProfile(userData).then(res => { }).catch(err => {
      console.log(err)
    })
  }

  const handleSwitch = (ev: any) => {
    const value = !!ev.target.checked
    setHasTwoClients(value)
    let setClients = [...form.clients]
    let errorClients = [...errorMessages.clients]
    if (value) {
      const newClient: UserClient = {
        name: "",
        lastname: "",
        phone: "",
        email: ""
      }
      setClients = [...form.clients, newClient]
      errorClients = [...errorClients, defaultClientErrors]
    } else {
      setClients = [form.clients[0]]
      errorClients = [errorMessages.clients[0]]
    }
    setForm({ type: "change", payload: { input: `clients`, value: setClients } })
    setErrorMessages(prev => ({ ...prev, clients: errorClients }))
  }

  const handleSetForm = (e: any) => {
    console.log("setForm ", e)
    setForm(e)
  }

  return (
    <Form
      className="form-schedule-visit position-relative"
      noValidate
      validated={send === "sended" || send === "error"}
      onSubmit={onSend}
    >
      <Form.Group className="mb-2" controlId="formScheduleVisitType">
        <Form.Label className="fw-bold fs-xs mb-1">Tipo de visita</Form.Label>
        <ToggleButtons
          options={[
            { value: "PRESENCIAL", text: "Presencial" },
            { value: "VIRTUAL", text: "Virtual" },
          ]}
          value={form.type}
          onChange={(value: string) =>
            setForm({ type: "change", payload: { input: "type", value } })
          }
        />
      </Form.Group>
      <Form.Group className="mb-1" controlId="formScheduleVisitDate">
        <DateCalendar
          value={form.date}
          onChange={(d: string) => {
            setForm({ type: "change", payload: { input: "date", value: d } });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-2" controlId="formScheduleVisitTime">
        <Form.Label className="fw-bold fs-xs mb-1">
          Horario de preferencia
        </Form.Label>
        <ToggleButtons
          options={[
            { value: "MORNING", text: "Mañana" },
            { value: "AFTERNOON", text: "Tarde" },
          ]}
          value={form.time}
          onChange={(value: string) =>
            setForm({ type: "change", payload: { input: "time", value } })
          }
        />
      </Form.Group>


      {
        (country == 'CO' || (property.country.code == 'CO' && country == 'PA')) &&
        <>
          <Form.Group className="mb-2" controlId="formSendOfferHours">
            <Form.Label className="fw-bold fs-xs mb-1">Opciones de Hora <small>(al menos 2)</small> </Form.Label>
            <SpecialSelect
              isMulti={true}
              options={available_hours}
              selected={form.hours}
              onChange={(value: OptionSelectType[]) => setForm({ type: "change", payload: { input: "hours", value } })}
              setInvalid={invalidHours}
            />

            {(invalidHours) &&
              send === "error" && (
                <Form.Text className="fs-xs lh-1 mt-0 text-danger">
                  Elija al menos 2 opciones
                </Form.Text>
              )}
          </Form.Group>
        
        </>
      }

      {
        showClientInsideSchedule && 
        (<>
          <Form.Group>
          <Form.Check // prettier-ignore
            type="switch"

            id="custom-switch"
            label="Registrar 2 clientes"
            checked={hasTwoClients}
            onChange={handleSwitch}
            className="mt-3 fs-sm"
          />
        </Form.Group>

        {
          form.clients.length > 0 &&
          form.clients.map((client, index) => <>
            <ClientForm key={index} index={index} form={form} setForm={handleSetForm} send={send || ''} errorMessages={errorMessages} requiredOtherThanName={false} />
          </>)
        }
      </>)
      }




      {
        requirePhone &&
        <Form.Group className="mb-2" controlId="formSendOfferPhone">
          <Form.Label className="fw-bold fs-xs mb-1">Teléfono</Form.Label>
          <Form.Control
            type="number"
            required={requirePhone}
            value={userPhone}
            className="fs-sm"
            onChange={(v) => {
              setUserPhone(v.target.value);
            }}
          />
          <Form.Text className="fs-xs lh-1 mt-0 ">
            Se registrará como teléfono principal.<br />
          </Form.Text>
          {(!userPhone || userPhone == '') &&
            send === "error" && (
              <Form.Text className="fs-xs lh-1 mt-0 text-danger">
                Este campo es requerido
              </Form.Text>
            )}
        </Form.Group>
      }


      <Form.Group className="mb-2" controlId="formSendOfferMessage">
        <Form.Label className="fw-bold fs-xs mb-1">Mensaje</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}

          value={form.message}
          className="fs-sm"
          onChange={(v) => {
            setForm({
              type: "change",
              payload: { input: "message", value: v.target.value },
            });
          }}
          placeholder={defaultMessage}
        />
        {(typeof form.message === "undefined" || form.message.length === 0) &&
          send === "error" && (
            <Form.Text className="fs-xs lh-1 mt-0 text-danger">
              Este campo es requerido
            </Form.Text>
          )}
      </Form.Group>


      <Button
        variant={send === "sended" ? "success" : "primary"}
        type="submit"
        className="send-button w-100"

        disabled={send === "sending" || send === "sended"}
      >
        {send === "sending" ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
        ) : null}
        {send === "sending"
          ? "Agendando"
          : send === "sended"
            ? "Visita agendada"
            : "Agendar visita"}
      </Button>

      <Alert variant="danger" show={showError}>
        <Button className="alert-btn" onClick={() => setShowError(false)}>
          <i className="bi bi-x"></i>
        </Button>
        <p>
          No pudimos enviar tu mensaje. <br />
          Por favor, intenta nuevamente.
        </p>
      </Alert>
    </Form>
  );
};
