import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { requestBenefits } from "../../data/api";
import { BenefitsType } from '../../interfaces/benefits';
import { sendEmail, sendFormulario } from "../../data/api";
import dayjs from "dayjs";
import { UtmArgs } from "../../interfaces/email"
import useUser from "../../hooks/user/useUser"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useCountry  from "../../hooks/config/useConfig";
import {useUserFetch}  from "../../hooks/fetch/useUserFetch";
import { UpdateUserBody, UserRequestedMortgageBody, UserRequestedWarrantyBody } from "../../interfaces/userActivity";
import { WarrantyInsurance, countryInsurances } from "../../data/warranty_insurances";

export const FeedBenefitsForm = ({ show, type, onSend }: { show: boolean, type: string, onSend: () => void }) => {
    const { userState } = useUser();
    const GA = useGoogleAnalytics(true);
    const [searchParams] = useSearchParams();
    const [isShow, setIsShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const {config,country, translation} = useCountry()
    const {registerRequestedWarranty , registerRequestedMortage,updateProfile} = useUserFetch()
    const [userPhone , setUserPhone] = useState('')

    const requirePhone = !userState.phone || userState.phone == '' ? true  : false

    const [send, setSend] = useState<null | "sending" | "error" | "sended">(null);
    const [credentials, setCredentials] = useState({
        name: "",
        email: "",
        phone: "",
        ci: "",
        amount: "",
        supplier: "",
    });
    const defaultNameMessage = "Nombre es obligatorio"
    const defaultEmailMessage = "Escriba un email válido"
    const defaultPhoneMessage = "Teléfono es obligatorio"
    const defaultCiMessage = translation.identity_doc + " es obligatorio"
    const defaultSupplierMessage = "Proveedor es obligatorio"
    const [nameMessage, setNameMessage] = useState(defaultNameMessage);
    const [emailMessage, setEmailMessage] = useState(defaultEmailMessage);
    const [phoneMessage, setPhoneMessage] = useState(defaultPhoneMessage);
    const [ciMessage, setCiMessage] = useState(defaultCiMessage);
    const [supplierMessage, setSupplierMessage] = useState(defaultSupplierMessage);

    const insurances : WarrantyInsurance[] = countryInsurances[country]

    const validateForm = () => {
        return credentials.name.length > 0 && credentials.email.length > 0 && credentials.phone.length > 0 && credentials.ci.length > 0 && (credentials.supplier.length > 0 || type !== "warranty");
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        form.name.setCustomValidity('')
        form.email.setCustomValidity('')
        form.phone.setCustomValidity('')
        form.ci.setCustomValidity('')
        if (type === "warranty") form.supplier.setCustomValidity('')
        setNameMessage(defaultNameMessage)
        setEmailMessage(defaultEmailMessage)
        setPhoneMessage(defaultPhoneMessage)
        setCiMessage(defaultCiMessage)
        setSupplierMessage(defaultSupplierMessage)
        setValidated(true);

        form.checkValidity() !== false && requestBenefits(type, credentials.name, credentials.email, credentials.phone, credentials.ci, credentials.supplier).then((response: BenefitsType) => {
            if (response.status === "error") {
                if (response.field === 'name') {
                    setNameMessage(response.message ?? "");
                    form.name.setCustomValidity('invalid')
                }
                if (response.field === 'email') {
                    setEmailMessage(response.message ?? "");
                    form.email.setCustomValidity('invalid')
                }
                if (response.field === 'phone') {
                    setPhoneMessage(response.message ?? "");
                    form.phone.setCustomValidity('invalid')
                }
                if (response.field === 'ci') {
                    setCiMessage(response.message ?? "");
                    form.ci.setCustomValidity('invalid')
                }
                if (response.field === 'supplier') {
                    setSupplierMessage(response.message ?? "");
                    form.supplier.setCustomValidity('invalid')
                }
            } else {
                setSend("sending");
                const credentialsFormat: any = { ...credentials };
                credentialsFormat.form_type = type;
                credentialsFormat['id_inmo'] = userState.id;
                credentialsFormat['name_inmo'] = `${userState.name} - ${userState.real_estate}`
                credentialsFormat['email_inmo'] = userState.email;
                credentialsFormat['phone_inmo'] = userState.phone || userPhone
                credentialsFormat['countryCode'] = country
                credentialsFormat['platform'] = 'Iris'

                let idForm = 0
                
                if(type === 'mortgage') idForm = 66;
                else if(type=='warranty'){
                    idForm = insurances.find(insurance => insurance.label == credentials.supplier)?.form_id || 0
                }

                const idPais = 1
                const formData = {
                    IDuser: parseInt(userState.id),
                    nombre: credentials.name,
                    email: credentials.email,
                    telefono: credentials.phone,
                    ci: credentials.ci,
                    fecha: dayjs().format("YYYY-MM-DD HH:mm"),
                    no_mailchimp_suscribe : true
                }
                
                //no_mailchimp_suscribe : para no suscribir a mailchimp

                let utm_content = `Datos de la inmobiliaria: ID '${userState.id}', Nombre '${userState.name}', Email '${userState.email}'`.slice(0,80)
                const utms: UtmArgs = {
                    utm_content: utm_content,
                    utm_source: "feed_iris",
                    utm_medium: searchParams.get("utm_medium") != null ? searchParams.get("utm_medium") : undefined,
                    utm_campaign: searchParams.get("utm_campaign") != null ? searchParams.get("utm_campaign") : undefined,
                }

                sendEmail(credentialsFormat).then((res: any) => {
                    
                    if (res.success) {
                        setSend("sended");
                        setCredentials({
                            name: "",
                            email: "",
                            phone: "",
                            ci: "",
                            amount: "",
                            supplier: "",
                        })
                        setValidated(false)
                        onSend();
                    } else {
                        setSend("error")
                        setShowError(true)
                    };
                })

                sendFormulario(formData, idForm, idPais, utms)
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err))

                GA.Event({ category: `User send ${type}`, action: `user_send_${type}`, label: `${userState.name}`, value: Number(userState.id) });


                saveRegister()

                if(requirePhone){
                    updatePhone()
                }
                
            }
        });
    };

    const updatePhone = () => {

        let userData : UpdateUserBody = {
          ...userState,
          phone : userPhone,
          real_estate : userState.real_estate || '',
          city : userState.city || '',
          primary_color : userState.primary_color || '',
          image : userState.image || '',
          image_file: null,
          delete_image: false,
        }
        updateProfile(userData).then(res => {}).catch(err => {
            console.log(err)
        })
    }

    const saveRegister = () => {

        if(type == 'mortgage'){
            const body : UserRequestedMortgageBody = {
                client_name : credentials.name,
                client_email : credentials.email,
                client_document : credentials.ci,
                client_phone : credentials.phone
            }

            registerRequestedMortage(body)
        }else{
            
            const body : UserRequestedWarrantyBody = {
                client_name : credentials.name,
                client_email : credentials.email,
                client_document : credentials.ci,
                client_phone : credentials.phone,
                amount : credentials.amount,
                insurance_carrier : credentials.supplier
            }
            registerRequestedWarranty(body)
        }
    }

    useEffect(() => {
        if (!isShow) {
            setCredentials({
                name: "",
                email: "",
                phone: "",
                ci: "",
                amount: "",
                supplier: "",
            })

            setValidated(false)
        }
    }, [isShow])

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="position-relative">
            <Row className="mb-3">
                <h2><strong className="fs-6">Datos de Cliente</strong></h2>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="name">
                    <Form.Label className="fw-bold text-secondary">Nombre</Form.Label>
                    <Form.Control
                        className="bg-sm-light border-0"
                        type="text"
                        value={credentials.name}
                        onChange={(e) =>
                            setCredentials({ ...credentials, name: e.target.value })
                        }
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {nameMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="email">
                    <Form.Label className="fw-bold text-secondary">Email</Form.Label>
                    <Form.Control
                        className="bg-sm-light border-0"
                        type="email"
                        value={credentials.email}
                        onChange={(e) =>
                            setCredentials({ ...credentials, email: e.target.value })
                        }
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {emailMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="phone">
                    <Form.Label className="fw-bold text-secondary">Teléfono</Form.Label>
                    <Form.Control
                        className="bg-sm-light border-0"
                        type="number"
                        value={credentials.phone}
                        onChange={(e) =>
                            setCredentials({ ...credentials, phone: e.target.value })
                        }
                        placeholder="123456789"
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {phoneMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="ci">
                    <Form.Label className="fw-bold text-secondary">{translation.identity_doc} </Form.Label>
                    <Form.Control
                        className="bg-sm-light border-0"
                        type="text"
                        value={credentials.ci}
                        onChange={(e) =>
                            setCredentials({ ...credentials, ci: e.target.value })
                        }
                        placeholder="1234567-8"
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {ciMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            {type === "warranty" &&
                <>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="supplier">
                            <Form.Label className="fw-bold text-secondary">{translation.warranty_insurance_label}</Form.Label>
                            <Form.Select
                                aria-label="Select who"
                                className="bg-sm-light border-0"
                                value={credentials.supplier}
                                onChange={(e) =>
                                    setCredentials({ ...credentials, supplier: e.target.value })
                                }
                                required
                            >
                                <option></option>
                                {
                                    insurances?.map((insurance ,i )=>
                                        <option key={i} value={insurance.label}>{insurance.label}</option>
                                    )
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {supplierMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="amount">
                            <Form.Label className="fw-bold text-secondary">Monto</Form.Label>
                            <Form.Control
                                className="bg-sm-light border-0"
                                type="number"
                                value={credentials.amount}
                                step="100"
                                onChange={(e) =>
                                    setCredentials({ ...credentials, amount: e.target.value })
                                }
                            />
                        </Form.Group>
                    </Row>
                </>
            }

            {
                requirePhone && 
                <>
                <Row className="mb-3">
                    <h2><strong className="fs-6">Tus Datos</strong></h2>
                </Row>
                <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="inmoPhone">
                            <Form.Label className="fw-bold text-secondary">Teléfono</Form.Label>
                            <Form.Control
                                className="bg-sm-light border-0"
                                type="number"
                                value={userPhone}
                                onChange={(e) =>
                                    setUserPhone( e.target.value)
                                }
                                placeholder="123456789"
                                required={requirePhone}
                            />
                            <Form.Control.Feedback type="invalid">
                                {phoneMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </>
                
            }
            
            <Button
                variant={send === "sended" ? "success" : "primary"}
                type="submit"
                className="send-button w-50"
                disabled={!validateForm() || send === "sending" || send === "sended"}
            >
                {send === "sending" ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                    />
                ) : null}
                {send === "sending"
                    ? "Enviando"
                    : send === "sended"
                        ? "Enviado"
                        : "Enviar"}
            </Button>

            <Alert variant="danger" show={showError}>
                <Button className="alert-btn" onClick={() => setShowError(false)}>
                    <i className="bi bi-x"></i>
                </Button>
                <p>
                    No pudimos enviar tu mensaje. <br />
                    Por favor, intenta nuevamente.
                </p>
            </Alert>
        </Form>
    )
}