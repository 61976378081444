
import { PreheaderData, PreheaderInfo } from "../../interfaces/ui"


export const preheadersInfo : PreheaderInfo[] = [
    {
        country : 'UY',
        preheaderRows : [
            {
                type : 'carousel' ,
                active : true ,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66a2593b0f912_encuentro-iris-preheader-desk-osaka-comision-jul-2024.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66a2593b6189e_encuentro-iris-prehead-mov-100.jpg",
                        linkToOpen : "https://iris.infocasas.com.uy/proyecto/3?operation=Venta"
                    },
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66b4e40b77506_banner-preheader-desktop.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66b4e40b4bdfc_banner-preheader-mobile.png",
                        linkToOpen : "https://www.infocasas.com.uy/iris-century-21A-evento/amp?utm_source=web&utm_medium=iris_preheader"
                    },
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66c351f26e2b2_banner-preheader-iris-desk.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66c351f242698_banner-preheader-iris-mobile.png",
                        linkToOpen : "https://www.infocasas.com.uy/proyectos/infocasas-talks/amp?utm_source=web&utm_medium=iris_preheader"
                    }
                ],
                shuffle : true
            }
        ]
    },
    {
        country : 'CO',
        preheaderRows : [
            {
                type : 'single',
                active : true,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/6633a0f15f85b_1142x122_-urbans-friends.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/6633a0f15f85b_1142x122_-urbans-friends.png"
                    }
                ]
            },
            {
                type : 'single',
                preheaders : [
                    {
                        urlImgDesktop : "/images/preheaders-popups/puerto-castilla-preheader-Desktop.gif",
                        urlImgMobile : "/images/preheaders-popups/puerto-castilla-preheader-Mobile.gif",
                        linkToOpen : "https://amp.fincaraiz.com.co/iris-od-puerto-castilla-29-agosto/amp?utm_source=web&utm_medium=iris_preheader",
                        endDate : '2024-08-30',
                        name : 'Puerto Castilla'
                    },
                ],
                active : true
            },
            {
                type : 'single',
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66a7d28cbaa0d_assets_preheaderdesktop1.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66a7d28c8c69b_assets_preheadermobile4.gif",
                        linkToOpen : "https://amp.fincaraiz.com.co/ViajeRepublicaDominicana-IRIS?utm_source=web&utm_medium=iris_preheader",
                    }
                ],
                active : true,
                shuffle : true
            }
        ]
    },
    {
        country : 'CL',
        preheaderRows : [
            {
                type : 'single',
                active : false, //INACTIVE
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66956eef71be7_feed-iris-desktop.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66956eef71aaa_feed-iris-mobile.png",
                        linkToOpen : "https://www.iris.yapo.cl/proyecto/779?operation=Venta"

                    }
                ]
            },
            {
                type : 'single',
                active : true,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66c74da809df2_feed-iris-desktop-propiedades-usadas.png",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66c74da80a1bb_feed-iris-mobile-propiedades-usadas.png",
                        linkToOpen : '/propiedades-usadas'
                    }
                ] 
            }
        ]
    },
    {
        country : 'PY',
        preheaderRows : [
            {
                type : 'single',
                active : true,
                preheaders : [
                    {
                        urlImgDesktop : "https://cdn1.infocasas.com.uy/web/66ad48eb63a59_ngo-iris-preheader-1142x122px.gif",
                        urlImgMobile : "https://cdn1.infocasas.com.uy/web/66ad48eb368bb_ngo-iris-preheader-427x102px.gif",
                        linkToOpen : "https://ngosaeca.com.py/division-corporativa"
                    }
                ]
            }
        ]
    },
    {
        country : 'PA',
        preheaderRows: [
            {
                type: "single",
                preheaders: [
                    {
                        urlImgDesktop: "https://cdn1.infocasas.com.uy/web/66c641665f757_aptta_portal-iris_preheaderdesktop.gif",
                        urlImgMobile: "https://cdn1.infocasas.com.uy/web/66c641665f506_aptta_portal-iris_preheader_mobile.gif",
                        linkToOpen : "https://amp.encuentra24.com/openday_iris/pa?utm_source=web&utm_medium=iris_preheader",
                        endDate : '2024-08-25'
                    }
                ],
                active: true
            }
        ]
    }
]