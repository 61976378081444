import { Button } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { DownloadComponent } from './DownloadComponent';
import useUser from "../../hooks/user/useUser";
import { User } from "../../interfaces/user";
import useCountry from "../../hooks/config/useConfig";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import { IconCardCheck } from "../CustomIcons/IconCardCheck";
import { ProjectType } from "../../interfaces/project";
import { downloadDocument } from "../../data/api";
import { IconDownload } from "../CustomIcons/IconDownload";


export const DownloadBrochure = ({ property, operation }: {
  property: ProjectType, operation?: OperationType
}) => {
  const { userState } = useUser();
  const componentRef = useRef(null);
  const fullDataPdf: any = property;


  fullDataPdf.logo = userState.image;
  fullDataPdf.inmo_name = userState.real_estate && userState.real_estate != ''? userState.real_estate : userState.name
  
  const { country, translation } = useCountry();
  const GA = useGoogleAnalytics()

  const handlePrint = () => {
    GA.Event({ category: "User print technical specifications", action: "user_print_tech_specs", label: `${userState.name}`, value: Number(userState.id) });
  }

  const handleDownload = (ev:any) => {
    ev.preventDefault()
    downloadDocument((property.brochure ?? ""), property.title, property.brochure?.split('.').pop() ?? "pdf");
    GA.Event({ category: "User download brochure", action: "user_download_brochure", label: `${userState.name}`, value: Number(userState.id) });
  }

  return (
    <>
      <div className="mt-3 text-center w-100" style={{minHeight: 40}}>
          {
            property.brochure && 
            <button className="btn btn-outline-primary" onClick={handleDownload}>Descargar Brochure <IconDownload width="18" className="mx-1" /> </button>
          }
          
      </div>
    </>
  );
};