import { useEffect, useState } from "react";
import { getDateYMD } from "../../utils/Functions";
import { PreheaderData } from "../../interfaces/ui";

export const PreHeader = ({urlImgDesktop, urlImgMobile, linkToOpen , clickBanner ,endDate} : 
  PreheaderData ) => {
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const now = new Date();
  const nowDate = new Date(getDateYMD())
  const end_date = endDate ? new Date(endDate) : null
  const validDateToShow = !end_date || end_date > nowDate ? true : false

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  const clickImage = () => {
    if(linkToOpen && linkToOpen != '') {window.open(linkToOpen)} 
    else if(clickBanner != null){clickBanner()}
  }
  
  if(!validDateToShow) {
    return null
  }
  
  return (
    <div className="event-container container px-1 mb-2">
           <img src={
             windowSize.innerWidth > 768 ?
               urlImgDesktop
               : urlImgMobile
           } className="banner-promo" alt="preheader-img" 
           style={{cursor : (linkToOpen && linkToOpen !='' ) || clickBanner ?'pointer' : 'auto'}}
           onClick={clickImage}/>
         </div>
    
  )
}