import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import useUser from "../../hooks/user/useUser";
import { IconHeart } from "../CustomIcons/IconHeart";
import { IconHeartFill } from "../CustomIcons/IconHeartFill";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import {useUserFetch} from "../../hooks/fetch/useUserFetch";
import { generalDataResult } from "../../interfaces/apiTypes";


export const PropertyFavorite = ({ id  }: { id : number }) => {

  const GA = useGoogleAnalytics()
  const {registerFavorite, removeFavorite} = useUserFetch()
  
  
  const {
    userState: { favorites = [], id: userId , name },
    setFavorites,
  } = useUser();
  const [isFavorite , setIsFavorite] = useState(favorites.includes(id))

  const setFavorite = async () => {
    
    GA.Event({ category: `User save favorite`, action: `user_save_favorite`, label: `${name}`, value: Number(userId) });

    let result : generalDataResult
    if(isFavorite){
      result = await removeFavorite({project_id : id})
    }else result = await registerFavorite({project_id : id})
    
    if(result.success){
      setFavorites(result.data);
      setIsFavorite(!!result.data.includes(id))
    }
  
  };


  return (
    <Button
      className="property-favorite fw-normal d-flex align-items-center text-secondary lh-1 px-0 px-lg-2"
      variant="outline-ligh"
      onClick={setFavorite}
    >
      {isFavorite ?
        <IconHeartFill width={20} height={20} className="text-primary" /> :
        <IconHeart width={20} height={20} className="text-primary" />
      }

      <span className="d-none d-lg-inline ms-2">
        {isFavorite ? "Guardado" : "Guardar"}
      </span>
    </Button>
  );
};
