import { useEffect, useState } from "react";
import { Button, Col, Container, ProgressBar, Row, Alert, Table, Modal, Form, Dropdown, DropdownButton } from "react-bootstrap";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import loaderIris from "../../../lotties/orange-loader.json";
import { formatDateToDMY,formatMoneyDecimals,getDateYMD } from "../../../utils/Functions";
import "./ListAppraisals.scss"
import { useAdminFetch } from "../../../hooks/fetch/useAdminFetch"
import useAlert from "../../../hooks/alert/useAlert";
import Pagination from "../../../components/ui/pagination/Pagination";
import { IconPencil } from "../../../components/CustomIcons/IconPencil";
import { IconFileEamarkExcelFill } from "../../../components/CustomIcons/IconFileEamarkExcelFill";
import { Appraisal } from "../../../interfaces/appraisal";
import { AlertVariants } from "../../../context/Alert/AlertContext";

export const ListAppraisals = () => {

    const MAX_PAGES = 4
    const LIMIT = 20
    const LIMIT_EXCEL = 1000
    const [appraisals, setAppraisals] = useState<Appraisal[]>([])
    const [page, setPage] = useState(1)
    const [searchName, setSearchName] = useState('')
    const [statusFilter, setStatusFilter] = useState('')
    const [loading, setLoading] = useState(false)
    const { getAdminAppraisals, exportAppraisals } = useAdminFetch()
    const [totalAppraisals, setTotalAppraisals] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const statusOptions = ['SOLICITADA','EN TRÁMITE','ENTREGADA','FALLIDA']

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.guess() //Adivina el timezone del usuario

    useEffect(() => {
        setLoading(true)
        loadAppraisals(page, LIMIT, '', '')
    }, [])

    const loadAppraisals = (loadPage: number, loadLimit: number,  filterStatus?: string ,searchTerm?: string) => {
        
        setPage(loadPage)
        if(searchTerm) setSearchName(searchTerm)
        if(filterStatus) setStatusFilter(filterStatus)
        
        getAdminAppraisals(loadPage, loadLimit, filterStatus , searchTerm ).then(data => {
            setLoading(false)
            if (data.success) {
                setAppraisals(data.data)
                setTotalAppraisals(data.total)
                let total_pages = Math.ceil(data.total_filter / loadLimit)
                setTotalPages(total_pages)
            }


        }).catch(err => {
            console.log(err)
        })
    }
    
    const handlePagination = (pageNav: number) => {
        if (pageNav != page) {
            setPage(pageNav)
            loadAppraisals(pageNav, LIMIT)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

 
    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }

    const delayValue = 250
    let timer: any = 0;
    const searchNameInput = (ev: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            //Hacemos algo
            if (ev.target.value.length > 1) {
                loadAppraisals(1,LIMIT,statusFilter,searchName)
            } else if (ev.target.value.length == 0) {
                loadAppraisals(1,LIMIT,statusFilter,'')
            }

        }, delayValue);
    }

    const keyDownHandle = (ev: any) => {
        clearTimeout(timer)
    }

    const handleChangeStatus = (status : string) => {
        setStatusFilter(status)
        loadAppraisals(1,LIMIT,status,searchName)
    }

    const exportExcel = () => {
        exportAppraisals(1, LIMIT_EXCEL, statusFilter , searchName).then(res => {
            
            if(res && res.success){
                const content = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const encodedUri = window.URL.createObjectURL(content);
                const link = document.createElement("a");
                let date = getDateYMD()
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `LISTADO-TASACIONES-IRIS-${date}.xlsx`);
                link.click();

            }else{
                showAlertMessage('danger', 'Ocurrió un error')
            }
            
        }).catch(err => console.error)
    }

    return (
        <div className="p-5 admin-sales-list">
            <div className="mb-4 d-flex justify-content-between flex-wrap ">
                <h3>TASACIONES </h3>
            </div>
            <div className="d-flex mb-2 sales-filter align-items-center flex-wrap justify-content-between">
                <div className="d-flex  align-items-center flex-wrap ">
                    <Form.Group className="sales-filter-search me-2 mb-2">
                        <Form.Control placeholder="Buscar inmobiliaria o email..." value={searchName} onKeyUp={searchNameInput} onChange={(ev) => setSearchName(ev.target.value)}
                            onKeyDown={keyDownHandle} />
                    </Form.Group>
                    <div className="d-flex align-items-center justify-content-start sales-filter-status">
                        <span className="mx-4 me-2 p-0">Filtrar Estado:</span>
                        <DropdownButton id="dropdown-basic-button" variant="outline-primary" title={!statusFilter || statusFilter == ''? 'Todas ': `${statusFilter}`}>
                            <Dropdown.Item onClick={() => handleChangeStatus('')}>Todas</Dropdown.Item>
                                {statusOptions.map((so,i) => <Dropdown.Item key={i} onClick={() => handleChangeStatus(so)}>{so}</Dropdown.Item> )}
                        </DropdownButton>
                    </div>
                    
                </div>

                <div className="export-excel">
                    <Button variant="outline-primary" 

                     onClick={exportExcel}>Exportar <IconFileEamarkExcelFill width='20' /> </Button>
                </div>
                
            
            </div>
            <div className=" text-end">
            <p className="mb-4 fs-small" style={{fontSize : '14px'}}>Mostrando {appraisals.length || 0} de {totalAppraisals}</p>
            </div>
            
            
            <div>
                {
                    appraisals?.length > 0 &&
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Inmobiliaria</th>
                                <th>Email</th>
                                <th>Empresa</th>
                                <th>ID Externo</th>
                                <th>Precio</th>
                                <th>Estado</th>
                                <th>Fecha</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                appraisals.map(appraisal => {

                                    //Convertir a fecha de zona de usuario y formatear
                                    const inmoName = appraisal.user.real_estate ? appraisal.user.name + ' - ' + appraisal.user.real_estate : appraisal.user.name

                                    return (
                                        <tr key={appraisal.id}>
                                            <td>{inmoName}</td>
                                            <td>{appraisal.user.email}</td>
                                            <td>
                                                <p><strong>{appraisal.company_name}</strong> </p>
                                            </td>
                                            <td>{appraisal.external_id}</td>
                                            <td>
                                                {appraisal.currency} {formatMoneyDecimals(appraisal.price)}
                                            </td>
                                           
                                            <td className="sale-info">{appraisal.status}</td>
                                            <td>{appraisal.created_at ? formatDateToDMY(appraisal.created_at,'-') : ''}</td>
                                            <td>
                                                
                                                <Link to={`edit/${appraisal.id}`}><button className="btn btn-outline-primary border-0"><IconPencil width={20} /> </button> </Link>
                                            </td>

                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </Table>
                }
                {
                    (!appraisals || loading) &&
                    <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                }
                {appraisals?.length > 0 && totalPages && <Pagination currentPage={page} handlePagination={handlePagination} totalPages={totalPages} maxPages={MAX_PAGES} />}

            </div>
            {
                !loading && (!appraisals || appraisals.length == 0) &&
                <div className="text-center">
                    <hr />
                    <p className="mt-4">No hay ninguna venta registrada.</p>
                </div>

            }

        </div>
    )
}