import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { DetailHeaderInmo } from "../fragments/detail/DetailHeaderInmo";
import { TopSectionInmo } from "../fragments/detail/TopSectionInmo";
import useUser from "../hooks/user/useUser";
import { PropertyType } from "../interfaces/property";
import useBreakpoint from "use-breakpoint";
import { AUTH_VALUES, BREAKPOINTS } from "../utils/Constants";
import "../styles/inmo_detail.scss";
import Lottie from "lottie-react";
import loaderIris from "../lotties/loader-iris.json";
import { useGoogleAnalytics } from "../hooks/googleAnalytics/useGoogleAnalytics";
import useAlert from "../hooks/alert/useAlert";
import {useFetch} from '../hooks/fetch/useFetch'

import { User } from "../interfaces/user";
import { decrypt } from "../data/api";

import {Helmet} from "react-helmet";

import useConfig from '../hooks/config/useConfig'
import { ProjectType } from "../interfaces/project";
import { AlertVariants } from "../context/Alert/AlertContext";
import { useLinks } from "../hooks/config/useLinks";


const InmoDetail = () => {
  const { isLoggedIn, userState,logoutUser } = useUser();
  const { id = "" ,inmoId = ""} = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [propertyUnsetted, setPropertyUnsetted] = useState(true);
  const [inmoUnsetted, setInmoUnsetted] = useState(true);
  const [property, setProperty] = useState<ProjectType>();
  const GA = useGoogleAnalytics(true);
  const {setTypeGlobalAlert,setShowGlobalAlert,setMessageGlobalAlert} = useAlert()
  const {getUserBasicInfo,getPublicProjectByIdentifier} = useFetch()

  const [blobImgShare , setBlobImgShare] = useState<Blob>(new Blob());
  const [inmobiliaria,setInmobiliaria] = useState<User>()
  const [operation , setOperation] = useState<any>(null)

  const {config } = useConfig()
  const { mainProjectsUrl } = useLinks()

  const [activeTab, setActiveTab] = useState<
    "RESUME" | "UNITS" | "LOCATION" | "FINANCIAL"
  >("RESUME");


  const showAlertMessage = (type : AlertVariants,message : string) => {
    setTypeGlobalAlert(type)
    setMessageGlobalAlert(message)
    setShowGlobalAlert(true)
    setTimeout(() => {
      setShowGlobalAlert(false)
      setTypeGlobalAlert('success')
      setMessageGlobalAlert('')      
    }, 3000);
    
  }

  useEffect(() => {
    if (property !== null && !propertyUnsetted && property) {
      GA.Event({ category: "User open project", action: "user_open_project", label: `${userState.name}, proyecto: ${property.identifier}`, value: Number(userState.id) });
      document.title = property.title;

      if (property.level && property.level > Number(userState.level) )
        navigate(mainProjectsUrl, { state: { from: pathname } })
    }
  }, [property]);

  useEffect(() => {

    let operation = searchParams.get("operation")
      //Convertir primera letra mayuscula por si acaso
      operation = operation? operation.charAt(0).toUpperCase() + operation.toLowerCase().slice(1) : null 
      let sendOperation = ''
    if (operation && (operation == 'Venta' || operation == 'Alquiler')) {
        sendOperation = operation
    }
    
    getPublicProjectByIdentifier(id,inmoId,sendOperation).then(async (property) => {
      
      if(property.success && property.data){
        
        //Guardar operacion y validar si tiene unidades
        
        let validUnits = true
        if(operation && operation=='Venta'||operation=='Alquiler'){
          setOperation(operation)
          const unitsOfOperation = property.data.units?.filter((u:any)=>u.operation == operation)
          if(!unitsOfOperation || unitsOfOperation.length == 0){
            validUnits = false
            window.location.href = `/inmobiliaria/${inmoId}/proyecto/${property.data.identifier}`
          }
        }else if(operation){//Operacion incorrecta
          validUnits = false
          window.location.href = `/inmobiliaria/${inmoId}/proyecto/${property.data.identifier}`
        }
        
        if(validUnits){
          setProperty(property.data)
          
        }
        setPropertyUnsetted(false)
        
      }else{

        //setProperty([])
        if(property.message && property.message !== ''){
          showAlertMessage('danger',property.message)
        }
        
        setPropertyUnsetted(false)
      }
      
    })

    
    const source = searchParams.get("source")
    if (!!source) {
      GA.Event({ category: "User comes from Panel", action: `user_comes_from_${source}`, label: `${userState.name}, proyecto: ${id}`, value: Number(userState.id) });
    }

  }, []);

  useEffect(()=>{
    
    if(config && config.country.code){
      let inmobiliaria_id = decrypt('hashId',inmoId) || ''
      try {
        inmobiliaria_id = inmobiliaria_id.split('-')[1]
      } catch (error) {}
      
      getUserBasicInfo(inmobiliaria_id).then((inmobiliaria)=>{
        if(inmobiliaria && inmobiliaria.success){
          setInmobiliaria(inmobiliaria.data)
          setInmoUnsetted(false)
          
          //Setear color :
          if(inmobiliaria.data.primary_color && inmobiliaria.data.primary_color  != ''){
            let inmoColor = inmobiliaria.data?.primary_color ;//#079156
            let inmoColorRgb = hexToRgb(inmoColor);
            var r = document.querySelector(':root') as HTMLElement;  
            r?.style?.setProperty('--inmo-primary-color', inmoColor);
            r?.style?.setProperty('--inmo-primary-color-rgb', inmoColorRgb);
          }
          
  
        }
      }).catch(err=> console.log(err.toString()))
    }
  },[config])


  const hexToRgb =(hex : string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ?  parseInt(result[1], 16) + ' , ' + parseInt(result[2], 16) 
    + ' , ' +parseInt(result[3], 16)    
     : null;
  }
  
  if ((property == null && propertyUnsetted ) || (inmobiliaria == null && inmoUnsetted)) {
    return <div>
        <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      <div className="vh-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
    </div>
  } else if ((property == null && !propertyUnsetted) || (inmobiliaria == null && !inmoUnsetted)) {
    return <Navigate to="/404" state={{ from: pathname }} />;
  } 

  const tabs = [
    { title: "Resumen", value: "RESUME" },
    { title: property?.type.identifier === "3" ? "Lotes" : "Unidades", value: "UNITS" },
    { title: "Ubicación", value: "LOCATION" },
    { title: "Financiera", value: "FINANCIAL" },
  ];
  
  return (
    <main className="detail inmo-detail">
        
      {property && property !== null && (
        <>
        <Helmet>
            <meta name="robots" content="noindex,nofollow" />
            <meta property="og:title" content={property.title} />
            <meta property="og:description" content={inmobiliaria?.real_estate} />
            <meta property="og:image" content={property.images[0]} />
        </Helmet>
        
          <DetailHeaderInmo property={property} blobImgShare={blobImgShare} 
          inmobiliaria={inmobiliaria} isInmoDetail={true}/>
          
          <div className="bg-xs-light detail-container">
            <Container className="position-relative content">
              <Row className="d-flex align-items-start">
                <Col lg="12" xl="12" className={"py-3 " + (breakpoint === "xs" ? "px-4" : "")}>
                  <div className="detail-content pt-5">
                    <Row className="gx-0 gy-4">
                      <Col lg="12">
                        <TopSectionInmo property={property} isInmoDetail={true} operation={operation} />
                      </Col>
                      {
                        inmobiliaria?.level && inmobiliaria.level != AUTH_VALUES.ADMIN_LEVEL.toString() && 
                        <Col lg="12">
                          <p >
                          {
                            inmobiliaria?.phone &&
                            <span className="mt-0 mb-0"><i className="bi bi-telephone text-primary"></i> {inmobiliaria.phone}</span>
                          }

                          {
                            inmobiliaria?.wpp_phone && 
                            <a className="mx-2  wpp-phone" href={"https://wa.me/"+inmobiliaria.wpp_phone} target="_blank">
                              <i className="bi bi-whatsapp"></i>
                              Enviar Whatsapp</a>
                          }
                          </p>
                          
                          <p className="text-primary mb-0">¡Contactame para más información!</p>
                          <p className="mb-0">{inmobiliaria?.name} </p>                        
                        </Col>
                      }
                      
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>         
          
        </>
        
      )}
    </main>
  );
};

export default InmoDetail;
