import { useState } from "react";
import { Col, Container, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { downloadDocument } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { NoteLines } from "../../components/CustomIcons/NoteLines";
import { User } from "../../interfaces/user";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import { ProjectType } from "../../interfaces/project";
import { usePromo } from "../../hooks/promo/usePromo";

export const DetailHeaderInmo = ({ property ,isInmoDetail, blobImgShare,inmobiliaria}: 
  { property: ProjectType, isInmoDetail? : boolean, blobImgShare? : Blob, inmobiliaria? : User }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { userState } = useUser();
  const GA = useGoogleAnalytics(true);
  const [show, setShow] = useState(false);
  const { countryPromo } = usePromo()
  const enableBlackFriday = !!countryPromo?.is_black_friday

  const handleDownloadBrochure = () => {
    downloadDocument((property.brochure ?? ""), property.title, property.brochure?.split('.').pop() ?? "pdf");

    GA.Event({ category: "User download brochure", action: "user_download_brochure", label: `${userState.name}`, value: Number(userState.id) });
  }


  return (
    <div className="detail-header w-100 bg-white border-bottom">
      <Container className={"h-100 " + (breakpoint === "xs" ? "px-4" : "")}>
        <Row className="h-100 d-flex align-items-center gx-3 position-relative">
          <Col className="d-flex flex-grow-1 align-items-center gap-2">
            {inmobiliaria && inmobiliaria.image && inmobiliaria?.image != "" && inmobiliaria?.image != null &&
              <img
                alt={inmobiliaria?.name}
                src={(inmobiliaria?.image != "" && inmobiliaria?.image != null) ? inmobiliaria?.image : "https://cdn1.infocasas.com.uy/web/62bdd922446a2_user-no-image.png"}
                width={50}
                height={50}
                className="rounded-circle me-4 mt-1"
                style={{width: "35px", height: "35px",objectFit:'contain' }}
              />
            }
            <h1 className="title fs-4 mb-1 fw-bold lh-1">{property?.title}</h1>
            {(enableBlackFriday && property.promos && property.promos !== "" && property.promos == 1) && 
                <img src="https://cdn1.infocasas.com.uy/web/6381001fb3831_etiqueta.png" className="logo-promo" />
            }
          </Col>
          
          
          <Col xs="auto" className="d-flex gap-1 gap-md-3">            
            {property.brochure !== "" && (
              <Button
                className="property-download px-2 py-0 w-100 text-start"
                onClick={handleDownloadBrochure}
                variant="outline"
              >
                <i className="bi bi-journal-bookmark-fill text-primary fs-5"></i>
                <span className="d-none d-lg-inline ms-lg-2">Descargar brochure</span>
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
