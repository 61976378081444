import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { UserProvider } from "./context/User/UserProvider";
import { PropertiesProvider } from "./context/Properties/PropertiesProvider";
import { FilterProvider } from "./context/Filter/FilterProvider";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Detail from "./pages/Detail";
import Feed from "./pages/Feed";
import Home from "./pages/Home";
import Layout from "./layouts/Layout";
import FeedLayout from "./layouts/FeedLayout";
import Listings from "./pages/Listings";
import FeedDetail from "./pages/FeedDetail";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import Logout from "./pages/Logout";
import InmoDetail from "./pages/InmoDetail";
import useCountry from "./hooks/config/useConfig";
import { useOneSignal } from "./hooks/oneSignal/useOneSignal";

import { GlobalAlert } from "./fragments/ui/GlobalAlert";
import { AlertProvider } from "./context/Alert/AlertProvider";
import ChangePassword from "./pages/ChangePassword";
import Register from "./pages/Register";
import { getDomainConfig } from "./data/api"

import AdminRoute from "./guards/AdminRoute"
import AdminNotifications from "./pages/admin/notifications/AdminNotifications"
import AdminCreateNotification from "./pages/admin/notifications/AdminCreateNotification"
import AdminLayout from "./layouts/AdminLayout";
import AdminSales from "./pages/admin/sales/AdminSales"

import InmoDetailLayout from "./layouts/InmoDetailLayout";

import RentGuarantees from "./pages/RentGuarantees";
import TermsConditions from "./pages/TermsConditions";
import Privacy from "./pages/Privacy";

import UserRoute from "./guards/UserRoute";
import UserProfile from "./pages/user/UserProfile";
import UserSales from "./pages/user/UserSales";
import UserActivity from "./pages/user/UserActivity";
import UserFavorites from "./pages/user/UserFavorites";
import UserPanel from "./daisyUI/pages/protected/ProfileSettings"
import UserCalendar from "./daisyUI/pages/protected/Calendar"
import AdminCreateSale from "./pages/admin/sales/AdminCreateSale";
import AdminEditSale from "./pages/admin/sales/AdminEditSale";
import { Courses } from "./pages/academy/Courses";
import { CourseDetail } from "./pages/academy/CourseDetail";
import { Appraisal } from "./pages/appraisal/Appraisal";
import { RequestAppraisal } from "./pages/appraisal/RequestAppraisal";
import Admin from "./pages/Admin";
import { AdminAppraisals } from "./pages/admin/appraisals/AdminAppraisals";
import AdminEditAppraisal from "./pages/admin/appraisals/AdminEditAppraisal";
import { PromoProvider } from "./context/Promo/PromoProvider";
import { ActivateUser } from "./pages/admin/users/ActivateUser";
import { ForbiddenRegularUser } from "./pages/ForbiddenRegularUser";
import { UsedProjects } from "./pages/projects/UsedProjects";
import { ProjectProvider } from "./context/Project/ProjectProvider";

function App() {
  const { setDomainConfig, config } = useCountry();
  const { InitOneSignal } = useOneSignal();
  

  useEffect(() => {
    getDomainConfig()
      .then((res: any) => {
        if (res && res.success) {
          setDomainConfig(res.data)
        }
      })
  }, []);

  useEffect(() => {
    if (config && config.oneSignalId) {
      InitOneSignal()
    }

  }, [config])

  return (
    <UserProvider>
      <PropertiesProvider>
        <ProjectProvider>
          <BrowserRouter>
            <ScrollToTop>
              <FilterProvider>
                <AlertProvider>
                  <PromoProvider>
                    <Routes>
                      <Route path="iniciar-sesion" element={<Login />} />
                      <Route path="registro" element={<Register />} />
                      <Route path="cambiar-credencial" element={<ChangePassword />} />
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="proyectos" element={<Listings />} />
                        <Route path="proyecto/:id" element={<Detail />} />
                        <Route path="propiedades-usadas" element={<UsedProjects />} />
                        
                        <Route path="/feed" element={<FeedLayout />}>
                          <Route index element={<Feed />} />
                          <Route path="/feed/:id" element={<FeedDetail />} />
                        </Route>
                        
                        <Route path="garantias-alquiler" element={<RentGuarantees />} />

                        <Route path="/terminos-y-condiciones" element={<TermsConditions />} />
                        <Route path="/privacidad" element={<Privacy />} />

                        <Route path="/tasaciones" element={<Appraisal />} />
                        <Route path="/solicitar-tasacion" element={<RequestAppraisal />} />

                        <Route path="/usuario" element={<UserRoute />} >
                          <Route index element={<Navigate to='/usuario/perfil' />} />
                          <Route path="perfil" element={<UserProfile />} />
                          <Route path="ventas" element={<UserSales />} />
                          <Route path="actividad" element={<UserActivity />} />
                          <Route path="favoritos" element={<UserFavorites />} />
                        </Route>

                        <Route path="cursos" element={<Courses />} />
                        <Route path="cursos/:id" element={<CourseDetail />} />

                        <Route path="forbidden" element={<ForbiddenRegularUser />} />

                        <Route path="*" element={<NoPage />} />

                      </Route>

                      
                      <Route path="/logout" element={<Logout />} />

                      <Route element={<InmoDetailLayout />}>
                          <Route path="inmobiliaria/:inmoId/proyecto/:id" element={<InmoDetail />} />
                      </Route>

                      <Route path="/admin" element={<AdminRoute />}>
                          <Route path="" element={<AdminLayout />} >
                            <Route index element={<Admin />} />
                            <Route path="notifications" element={<AdminNotifications />} />
                            <Route path="notifications/create" element={<AdminCreateNotification />} />
                            <Route path="sales" element={<AdminSales />} />
                            <Route path="sales/create" element={<AdminCreateSale />} />
                            <Route path="sales/edit/:id" element={<AdminEditSale />} />
                            <Route path="appraisals" element={<AdminAppraisals />} />
                            <Route path="appraisals/edit/:id" element={<AdminEditAppraisal />} />
                            <Route path="users/activate-user" element={<ActivateUser />} />
                        </Route>

                      </Route>
                      </Routes>
                  
                    <GlobalAlert />
                  </PromoProvider>
                </AlertProvider>
              </FilterProvider>
            </ScrollToTop>
          </BrowserRouter>
        </ProjectProvider>
      </PropertiesProvider>
    </UserProvider>
  );
}

export default App;
