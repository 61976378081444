import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PropertyAgentCommision } from "../../components/PropertyAgentCommision/PropertyAgentCommision";
import { PropertyBedroom } from "../../components/PropertyBedroom/PropertyBedroom";
import { PropertyFormButton } from "../../components/PropertyFormButton/PropertyFormButton";
import { PropertyGallery } from "../../components/PropertyGallery/PropertyGallery";
import { PropertyMap } from "../../components/PropertyMap/PropertyMap";
import { PropertyMeters } from "../../components/PropertyMeters/PropertyMeters";
import { PropertyPrice } from "../../components/PropertyPrice/PropertyPrice";
import { OperationType, PropertyType } from "../../interfaces/property";
import useBreakpoint from "use-breakpoint";
import { AUTH_VALUES, BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useUser from "../../hooks/user/useUser";
import JSZip from "jszip";
import fileDownload from 'js-file-download';
import { IconDownloadImage } from "../../components/CustomIcons/IconDownloadImage";
import { validateYoutubeVideo } from "../../utils/Functions";
import { PropertyTypeAndOperation } from "../../components/PropertyTypeAndOperation/PropertyTypeAndOperation";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType } from "../../interfaces/project";

export const TopSection = ({ property, operation  }: { property: ProjectType, operation?: OperationType }) => {
  const [map, setMap] = useState(false);
  const [minProjectPrice , setMinProjectPrice] = useState<{currency : string , price : number}>()
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { userState } = useUser();
  const zip = new JSZip();
  const GA = useGoogleAnalytics()
  const { translation } = useCountry();
  const hasRentCommision = property.financial.commision_rent &&
  property.financial.commision_rent > 0 &&
  property.financial.commision_rent !== "" ? true : false

  async function getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject("error");
      };
      reader.readAsDataURL(blob);
    })
  }

  const handleDownload = async () => {

    GA.Event({ category: "User download gallery", action: "user_download_gallery", label: `${userState.name}`, value: Number(userState.id) });

    const img: any = zip.folder("gallery");
    const filteredImages = property.images.filter((url) => !validateYoutubeVideo(url))
    const promises = filteredImages.map(async (url, index) => {
      return await getBase64ImageFromUrl(url)
        .then((result: any) => {
          const imageData = result.split(",")[1]
          let extension = "jpeg"

          switch (imageData.charAt(0)) {
            case "i":
              extension = "png"
              break;
            case "R":
              extension = "gif"
              break;
            case "U":
              extension = "webp"
              break;
          }

          img.file(`${property.title}-${index}.${extension}`, imageData, { base64: true });
        }).catch(err => console.log(err.toString()))
    })
    await Promise.all(promises);

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        fileDownload(content, "gallery.zip");
      });
  }

  return (
    <>
      <Row className="d-flex align-items-stretch justify-content-between gx-4 gy-3">
        <Col
          lg={{ order: 1, span: 4 }}
          xs={{ order: 2 }}
          className={"d-flex flex-column align-items-start " + (breakpoint === "xs" ? "mt-5" : "")}
        >
          <div className="d-flex justify-content-between w-100 align-items-start d-lg-block">
            <PropertyTypeAndOperation propertyType={property.type} operation={operation} />

            <div className="fw-normal text-black-50 mt-lg-2">
              {property.type.identifier !== "3" && <PropertyBedroom units={property.units} />}
              <PropertyMeters units={property.units} />
            </div>
          </div>
          <div className="detail-price flex-grow-1 py-lg-4 pt-2 pb-3 d-flex flex-column justify-content-center">
            <PropertyPrice units={property.units} garages={property.garages} isCol propertyTypeId={property.type.identifier}
              operation={operation} setMinProjectPrice={setMinProjectPrice} />

            {operation === "Venta" ? (
              property.financial.commission.percent > 0 ? (
                <PropertyAgentCommision commision={property.financial.commission.percent} 
                old_before_promo={property.financial.commission.old_before_promo} promos={property.promos}/>
              ) : (
                Number(userState.level) != AUTH_VALUES.ADMIN_LEVEL ? (
                  <PropertyAgentCommision commision={property.financial.commission.percent} 
                  old_before_promo={property.financial.commission.old_before_promo} promos={property.promos}/>
                ) : (
                  null
                )
              )
            ) : operation === "Alquiler" ? (
              (hasRentCommision) && (
                <div className="property-agent-commision text-uppercase text-secondary bg-white rounded-1 shadow-sm border mt-2">
                  <span className="text-hoverable">Comisión {translation.rent}: </span>
                  <span className="text-green">{property.financial.commision_rent}%</span>
                </div>
              )
            ) :
              (
                <>
                  {property.financial.commission.percent > 0 && (
                    <PropertyAgentCommision commision={property.financial.commission.percent} 
                    old_before_promo={property.financial.commission.old_before_promo} promos={property.promos}/>
                  )}
                  {hasRentCommision && (
                    <div className="property-agent-commision text-uppercase text-secondary bg-white rounded-1 shadow-sm border mt-2">
                      <span className="text-hoverable">Comisión {translation.rent}: </span>
                      <span className="text-green">{property.financial.commision_rent}%</span>
                    </div>
                  )}
                </>
              )}
          </div>
          <div className="property-location">
            <p className="property-address mb-0 lh-sm">{property.address}</p>
            <p className="property-hood text-black-50 lh-sm mb-0 mb-lg-3">
              {property.commune ?  `${property.commune.name}, `: ''}
              {property.zone ? (property.country.code === "CL" ? `Comuna ${property.zone.name}, ` : `${property.zone.name}, `) : ''}
              {property.country.code === "CL" && "Región "}
              {property.department?.name}
            </p>
          </div>
          <div className="d-none d-lg-block">
            <PropertyFormButton property={property} type="OFFER" />
          </div>

        </Col>
        <Col lg={{ order: 1, span: 8 }} xs={{ order: 1 }} className="detail-gallery">
          <div className="w-100 h-100 position-relative border rounded-3">
            <div className="country-flag rounded-1 shadow-sm" style={{ width: "60px", height: "40px" }}>
              <CustomImage src={property.country?.flag} width={50} height={50} />
            </div>
            <div className="toggle-map-gallery bg-white shadow d-flex align-items-center py-1 px-1 rounded-3">
              <div
                className={`toggle-button ${map ? "text-black-50" : "bg-sm-light text-primary"
                  } px-2 py-1 me-1 rounded-2`}
                onClick={() => setMap(false)}
              >
                Galería
              </div>
              <div
                className={`toggle-button ${!map ? "text-black-50" : "bg-sm-light text-primary"
                  } px-2 py-1 rounded-2`}
                onClick={() => setMap(true)}
              >
                Mapa
              </div>
            </div>
            <button onClick={handleDownload} className="btn position-absolute rounded-3 download-images">
              <IconDownloadImage />
            </button>
            {map && property.latitude && property.longitude ? (
              <PropertyMap
                latitude={parseFloat(property.latitude)}
                longitude={parseFloat(property.longitude)}
                expandable
                property={property}
              />
            ) : (
              <PropertyGallery
                images={property.images}
                infinite
                counter
                expandable
                property={property}
                minProjectPrice={minProjectPrice}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
