import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { validRequestNotarial } from "../../data/api";
import { sendEmail, sendFormulario } from "../../data/api";
import dayjs from "dayjs";
import { UtmArgs } from "../../interfaces/email"
import useUser from "../../hooks/user/useUser"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useCountry  from "../../hooks/config/useConfig";
import {useUserFetch}  from "../../hooks/fetch/useUserFetch";
import { UpdateUserBody, UserNotarialBody } from "../../interfaces/userActivity";
import { WarrantyInsurance, countryInsurances } from "../../data/warranty_insurances";
export const NotarialForm = ({ show, onSend }: { show: boolean, onSend: () => void }) => {
    const type = 'notarial'
    const { userState } = useUser();
    const GA = useGoogleAnalytics(true);
    const [searchParams] = useSearchParams();
    const [isShow, setIsShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const {config,country, translation} = useCountry()
    const {registerRequestedWarranty , registerRequestedMortage,updateProfile , registerRequestedNotarial} = useUserFetch()
    const [userPhone , setUserPhone] = useState('')

    const requirePhone = !userState.phone || userState.phone == '' ? true  : false

    const [send, setSend] = useState<null | "sending" | "error" | "sended">(null);
    const [credentials, setCredentials] = useState({
        name: "",
        phone: "",
        amount: "",
        bank_financing : ""
    });
    const defaultNameMessage = "Nombre es obligatorio"
    const defaultEmailMessage = "Escriba un email válido"
    const defaultPhoneMessage = "Teléfono es obligatorio"
    const defaultCiMessage = translation.identity_doc + " es obligatorio"
    const defaultSupplierMessage = "Proveedor es obligatorio"
    const [nameMessage, setNameMessage] = useState(defaultNameMessage);
    const [emailMessage, setEmailMessage] = useState(defaultEmailMessage);
    const [phoneMessage, setPhoneMessage] = useState(defaultPhoneMessage);
    const [ciMessage, setCiMessage] = useState(defaultCiMessage);
    const [supplierMessage, setSupplierMessage] = useState(defaultSupplierMessage);

    const insurances : WarrantyInsurance[] = countryInsurances[country]

    const validateForm = () => {
        return credentials.name.length > 0 && credentials.phone.length > 0 && credentials.bank_financing;
    };

    const handleSubmit = (event: any) => {
        try {
            
            event.preventDefault();
            const form = event.currentTarget;
            form.name.setCustomValidity('')
            form.phone.setCustomValidity('')
            form.amount.setCustomValidity('')
            form.bank_financing.setCustomValidity('')
            
            setNameMessage(defaultNameMessage)
            setEmailMessage(defaultEmailMessage)
            setPhoneMessage(defaultPhoneMessage)
            setCiMessage(defaultCiMessage)
            setSupplierMessage(defaultSupplierMessage)
            setValidated(true);

            if(form.checkValidity() !== false){
                const validFields = validRequestNotarial(credentials.name, credentials.phone, credentials.bank_financing, credentials.amount)

                if (validFields.status === "error") {
                    if (validFields.field === 'name') {
                        setNameMessage(validFields.message ?? "");
                        form.name.setCustomValidity('invalid')
                    }
                    if (validFields.field === 'phone') {
                        setPhoneMessage(validFields.message ?? "");
                        form.phone.setCustomValidity('invalid')
                    }
                    if (validFields.field === 'bank_financing') {
                        setSupplierMessage(validFields.message ?? "");
                        form.supplier.setCustomValidity('invalid')
                    }
                } else {
                    setSend("sending");
                    const credentialsFormat: any = { ...credentials };
                    credentialsFormat.form_type = 'notarial';
                    credentialsFormat['id_inmo'] = userState.id;
                    credentialsFormat['name_inmo'] = `${userState.name} - ${userState.real_estate}`
                    credentialsFormat['email_inmo'] = userState.email;
                    credentialsFormat['phone_inmo'] = userState.phone || userPhone
                    credentialsFormat['countryCode'] = country
                    credentialsFormat['platform'] = 'Iris'

                    const idForm = 2072
                    
                    const idPais = 1
                    const formData = {
                        IDuser: parseInt(userState.id),
                        nombre: credentials.name,
                        telefono: credentials.phone,
                        fecha: dayjs().format("YYYY-MM-DD HH:mm"),
                        no_mailchimp_suscribe : true
                    }
                    
                    //no_mailchimp_suscribe : para no suscribir a mailchimp

                    let utm_content = `Datos de la inmobiliaria: ID '${userState.id}', Nombre '${userState.name}', Email '${userState.email}'`.slice(0,80)
                    const utms: UtmArgs = {
                        utm_content: utm_content,
                        utm_source: "feed_iris",
                        utm_medium: searchParams.get("utm_medium") != null ? searchParams.get("utm_medium") : undefined,
                        utm_campaign: searchParams.get("utm_campaign") != null ? searchParams.get("utm_campaign") : undefined,
                    }

                    sendEmail(credentialsFormat).then((res: any) => {
                        
                        if (res.success) {
                            setSend("sended");
                            setCredentials({
                                name: "",
                                phone: "",
                                amount: "",
                                bank_financing : ""
                            })
                            setValidated(false)
                            onSend();
                        } else {
                            setSend("error")
                            setShowError(true)
                        };
                    })

                    sendFormulario(formData, idForm, idPais, utms)
                        .then((res) => console.log(res))
                        .catch((err) => console.log(err))

                    GA.Event({ category: `User send ${type}`, action: `user_send_${type}`, label: `${userState.name}`, value: Number(userState.id) });


                    saveRegister()

                    if(requirePhone){
                        updatePhone()
                    }
                    
                }
            }
        } catch (error) {
            console.log(error)
        }
        
        
    };

    const updatePhone = () => {

        let userData : UpdateUserBody = {
          ...userState,
          phone : userPhone,
          real_estate : userState.real_estate || '',
          city : userState.city || '',
          primary_color : userState.primary_color || '',
          image : userState.image || '',
          image_file: null,
          delete_image: false,
        }
        updateProfile(userData).then(res => {}).catch(err => {
            console.log(err)
        })
    }

    const saveRegister = () => {

        const body : UserNotarialBody = {
            client_name : credentials.name,
            client_phone : credentials.phone,
            amount : credentials.amount,
            bank_financing : credentials.bank_financing
        }
        registerRequestedNotarial(body)
    }

    useEffect(() => {
        if (!isShow) {
            setCredentials({
                name: "",
                phone: "",
                amount: "",
                bank_financing: "",
            })

            setValidated(false)
        }
    }, [isShow])

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="position-relative">
            <Row className="mb-3">
                <h2><strong className="fs-6">Datos de Cliente</strong></h2>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="name">
                    <Form.Label className="fw-bold text-secondary">Nombre Completo</Form.Label>
                    <Form.Control
                        className="bg-sm-light border-0"
                        type="text"
                        value={credentials.name}
                        onChange={(e) =>
                            setCredentials({ ...credentials, name: e.target.value })
                        }
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {nameMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="phone">
                    <Form.Label className="fw-bold text-secondary">Teléfono</Form.Label>
                    <Form.Control
                        className="bg-sm-light border-0"
                        type="number"
                        value={credentials.phone}
                        onChange={(e) =>
                            setCredentials({ ...credentials, phone: e.target.value })
                        }
                        placeholder="123456789"
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {phoneMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="amount">
                    <Form.Label className="fw-bold text-secondary">Monto sugerido de la operación (USD)</Form.Label>
                    <Form.Control
                                required
                                className="bg-sm-light border-0"
                                type="number"
                                value={credentials.amount}
                                step="100"
                                onChange={(e) =>
                                    setCredentials({ ...credentials, amount: e.target.value })
                                }
                    />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="bank_financing">
                    <Form.Label className="fw-bold text-secondary">Con financiación bancaria</Form.Label>
                    <Form.Select
                                aria-label="Select who"
                                className="bg-sm-light border-0"
                                value={credentials.bank_financing}
                                onChange={(e) =>
                                    setCredentials({ ...credentials, bank_financing: e.target.value })
                                }
                                required
                            >
                                <option></option>
                                <option value="Sí">Sí</option>
                                <option value="No">No</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            {
                requirePhone && 
                <>
                <Row className="mb-3">
                    <h2><strong className="fs-6">Tus Datos</strong></h2>
                </Row>
                <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="inmoPhone">
                            <Form.Label className="fw-bold text-secondary">Teléfono</Form.Label>
                            <Form.Control
                                className="bg-sm-light border-0"
                                type="number"
                                value={userPhone}
                                onChange={(e) =>
                                    setUserPhone( e.target.value)
                                }
                                placeholder="123456789"
                                required={requirePhone}
                            />
                            <Form.Control.Feedback type="invalid">
                                {phoneMessage}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </>
                
            }
            
            <Button
                variant={send === "sended" ? "success" : "primary"}
                type="submit"
                className="send-button w-100 mt-2"
                disabled={send === "sending" || send === "sended"}
            >
                {send === "sending" ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                    />
                ) : null}
                {send === "sending"
                    ? "Enviando"
                    : send === "sended"
                        ? "Enviado"
                        : "Enviar"}
            </Button>

            <Alert variant="danger" show={showError}>
                <Button className="alert-btn" onClick={() => setShowError(false)}>
                    <i className="bi bi-x"></i>
                </Button>
                <p>
                    No pudimos enviar tu mensaje. <br />
                    Por favor, intenta nuevamente.
                </p>
            </Alert>
        </Form>
    )
}