import { useEffect } from "react";
import ReactGA from "react-ga4";
import useUser from "../user/useUser";
// import { useLocation } from "react-router-dom";
import { useFetch } from "../../hooks/fetch/useFetch"
import { AUTH_VALUES } from "../../utils/Constants";

const TRACKING_ID = "G-0EZP3B7K3Q";
const enableRealAnalytics = process.env.REACT_APP_ENABLE_ANALYTICS && process.env.REACT_APP_ENABLE_ANALYTICS == 'true' ? true : false
//const dev: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const dev : boolean = !process.env.NODE_ENV || (process.env.NODE_ENV  && process.env.NODE_ENV !== 'production')
// const dev: boolean = false;

export const useGoogleAnalytics = (isInitializer: boolean = false) => {
  const { userState } = useUser();
  const { trackEventUser } = useFetch()

  let allowedUser = true;
  if (userState && userState.level && userState.level === AUTH_VALUES.ADMIN_LEVEL.toString()) allowedUser = false;

  const Init = () => {

      if (!dev && enableRealAnalytics) {
        ReactGA.initialize(TRACKING_ID);
        console.log(
          "%cInit Google Analytics",
          "color:white; background: #faaa00; padding:2px 4px"
        );
      } else {
        console.log(
          "%cInit Google Analytics (Not real)",
          "color:white; background: #cecece; padding:2px 4px"
        );
      }

  };

  const PageViewEvent = () => {
    if (allowedUser) {
      if (!dev && enableRealAnalytics) {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        console.log(
          "%cGoogle Analytics PageView",
          "color:white; background: #faaa00; padding:2px 4px"
        );
      } else {
        console.log(
          "%cGoogle Analytics PageView Test (Not real)",
          "color:white; background: #cecece; padding:2px 4px"
        );
      }


    }
  };

  const GAevent = async ({
    action,
    category,
    label = "",
    value = 1,
  }: {
    action: string;
    category: string;
    label?: string;
    value?: number;
  }) => {
    //No trackear usuarios con level 11
    if ( allowedUser) {
      if (!dev && enableRealAnalytics) {
        ReactGA.event({
          action: action,
          category: category,
          label: label,
          value: value,
        });

        console.log(
          "%cGoogle Analytics Event " + category,
          "color:white; background: #faaa00; padding:2px 4px"
        );
      } else {
        console.log(
          "%cGoogle Analytics Event Test: " + category + ' (Not real)',
          "color:white; background: #cecece; padding:2px 4px"
        );
      }


      let actionName = action.toLowerCase();
      actionName = actionName.charAt(0).toUpperCase() + actionName.slice(1);
      const events = ["Project_list_open", "User_open_project", "User_login", "User_open_feed", "User_schedule_visit", 
      "User_send_offer", "User_download_brochure", "User_send_warranty", "User_send_mortgage", "Banner_view_fianza_bogota", 
      "Banner_view_fianza_profesional","Banner_click_fianza_bogota", "Banner_click_fianza_profesional", 
      "User_save_favorite", "User_open_profile", 
        "User_open_activity_offers", "User_open_activity_visits", "User_open_favorites", "User_open_sales" ,
        "User_print_tech_specs","User_add_calendar","User_estimates_unit","User_open_course","Courses_list_open",
      "User_register_client","User_send_notarial"]

      if (events.includes(actionName)) {
        trackEventUser(actionName, userState.id,category, value.toString(), label)

      }


    }
  };

  useEffect(() => {
    if (isInitializer && TRACKING_ID && allowedUser) {
      Init();
      PageViewEvent();
      setTimeout(() => {
        GAevent({
          category: "usario_valido",
          action: "el usuario paso mas de 30 segundos - IRIS",
        });
      }, 30000);
    }
  }, []);

  return { Event: GAevent };
};
