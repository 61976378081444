import { Button } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { DownloadComponent } from './DownloadComponent';
import useUser from "../../hooks/user/useUser";
import { User } from "../../interfaces/user";
import useCountry from "../../hooks/config/useConfig";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import { IconCardCheck } from "../CustomIcons/IconCardCheck";
import { ProjectType } from "../../interfaces/project";


export const PropertyDownload = ({ property, operation }: {
  property: ProjectType, operation?: OperationType
}) => {
  const { userState } = useUser();
  const componentRef = useRef(null);
  const fullDataPdf: any = property;


  fullDataPdf.logo = userState.image;
  fullDataPdf.inmo_name = userState.real_estate && userState.real_estate != ''? userState.real_estate : userState.name
  
  const { country, translation,countryFeatures } = useCountry();
  const GA = useGoogleAnalytics()

  const handlePrint = () => {
    GA.Event({ category: "User print technical specifications", action: "user_print_tech_specs", label: `${userState.name}`, value: Number(userState.id) });
  }

  return (
    <>
      <ReactToPrint
        trigger={
          () => <Button
            className="property-download px-2 py-0 w-100 text-start"
            variant="outline"
          >
            <IconCardCheck width={20} height={20} className="text-primary" />
            <span className="d-lg-inline ms-lg-2">Imprimir ficha técnica</span>
          </Button>
        }
        pageStyle={'@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 200mm !important }} @page { margin: 20px 20px 20px 20px !important; }'}
        content={() => componentRef.current}
        onBeforePrint={handlePrint}
      />
      <div style={{ display: "none" }}>
        <DownloadComponent ref={componentRef} {...fullDataPdf} operation={operation} translation={translation} country={country} propertyCountry={property.country}  countryFeaturesWeb={countryFeatures}/>
      </div>
    </>
  );
};