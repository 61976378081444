import { Col, Container, Row, Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PropertyDownload } from "../../components/PropertyDownload/PropertyDownload";
import { PropertyFavorite } from "../../components/PropertyFavorite/PropertyFavorite";
import { PropertyShare } from "../../components/PropertyShare/PropertyShare";
import { OperationType, PropertyType } from "../../interfaces/property";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { downloadDocument } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { PropertyLinkShare } from "../../components/PropertyShare/PropertyLinkShare";
import useCountry from "../../hooks/config/useConfig";
import { IconArchive } from "../../components/CustomIcons/IconArchive";
import { IconBookmark } from "../../components/CustomIcons/IconBookmark";
import { IconFileText } from "../../components/CustomIcons/IconFileText";
import { ProjectType } from "../../interfaces/project";
import { EstimatorButton } from "../../components/Estimator/EstimatorButton";
import { useEffect, useState } from "react";
import { useCookies } from "../../hooks/cookies/useCookies";
import { usePromo } from "../../hooks/promo/usePromo";


export const DetailHeader = ({ property, blobImgShare, operation }:
  { property: ProjectType, blobImgShare?: Blob, operation?: OperationType }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { userState } = useUser();
  const GA = useGoogleAnalytics(true);
  const { translation ,countryFeatures } = useCountry();
  const [openNew , setOpenNew ] = useState(true)
  const { getCookie, setCookie } = useCookies();
  const { countryPromo } = usePromo()
  const enableBlackFriday = !!countryPromo?.is_black_friday

  useEffect(()=> {
    if (!getCookie("showNewTooltipEstimator")) {
        setOpenNew(true);
    } else {
        setOpenNew(false)
    };
  },[])

  const handleDownloadBrochure = () => {
    downloadDocument((property.brochure ?? ""), property.title, property.brochure?.split('.').pop() ?? "pdf");

    GA.Event({ category: "User download brochure", action: "user_download_brochure", label: `${userState.name}`, value: Number(userState.id) });
  }

  const handleDownloadExtraDocument = () => {
    downloadDocument((property.extra_file ?? ""), property.title, property.extra_file?.split('.').pop() ?? "pdf");

    GA.Event({ category: "User download extra doc", action: "user_download_extra", label: `${userState.name}`, value: Number(userState.id) });
  }

  const closeTooltip = () => {
    setCookie("showNewTooltipEstimator", "=1;expires=-1;path=/;SameSite=none;Secure");
    setOpenNew(false);
  }


  return (
    <div className="detail-header w-100 bg-white border-bottom">
      <Container className={"h-100 " + (breakpoint === "xs" ? "px-4" : "")}>
        <Row className="h-100 d-flex align-items-center gx-3 position-relative">
          <Col className="d-flex flex-grow-1 align-items-center gap-2">

            <h1 className="title fs-4 mb-1 fw-bold lh-1">{property?.title}</h1>
            {(enableBlackFriday && property.promos && property.promos == 1) &&
              <img src="https://cdn1.infocasas.com.uy/web/6381001fb3831_etiqueta.png" alt="Logo promo" className="logo-promo" />
            }
          </Col>
          <Col xs="auto">
            <PropertyFavorite id={property.id}/>
          </Col>

          {
            countryFeatures.has_estimation && property.countryFeatures?.has_estimation &&

            <OverlayTrigger
            show={openNew}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
                <Tooltip className="new-estimator" {...props}>
                    <div>
                        <Button className="new-estimator-close" onClick={closeTooltip}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                        <div className="pt-2 pe-3 text-start">
                            <span className="fw-bold">¡NUEVO! </span>
                            <span>Ahora puedes cotizar unidades</span>
                        </div>
                    </div>
                </Tooltip>
            )}
          >
            <Col xs='auto'  lg="auto" >
              <EstimatorButton project={property} unit={property.units[0]} pie={20} />
            </Col>
          </OverlayTrigger>
          }
            
          
          {
            blobImgShare &&
            <Col xs='auto' lg="auto" className="col-share">
              <PropertyShare blobImgShare={blobImgShare} />
            </Col>
          }
          
          <Col xs='auto' lg="auto" className="col-share-link">
            <PropertyLinkShare property={property} operation={operation} />
          </Col>

          <Col xs="auto" className="d-flex gap-1 gap-md-3 px-0">
            <Dropdown autoClose="outside" align={"end"}>
              <Dropdown.Toggle
                variant="info"
                className="bg-white filter-estate py-0 px-2 border-0 float-end d-flex align-items-center"
              >
                <span className="text-button text-secondary fw-normal d-flex align-items-center text-secondary lh-1 p-0">
                  <IconArchive width={20} height={20} className="text-primary" />
                  <span className="d-none d-lg-inline ms-2">Archivos</span>
                </span>

                <i className="bi bi-chevron-down text-light-secondary ms-2 d-flex align-items-center"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="px-1 py-0 border shadow files-dropdown"
                style={{ maxHeight: 270, overflow: "auto" }}
              >
                <Dropdown.Item
                  className="d-flex align-items-center px-2 py-2 my-1 rounded-1"
                >
                  <PropertyDownload property={property} operation={operation} />
                </Dropdown.Item>

                {(property.brochure && property.brochure !== "") && (
                  <Dropdown.Item
                    className="d-flex align-items-center px-2 py-2 my-1 rounded-1"
                  >

                    <Button
                      className="property-download px-2 py-0 w-100 text-start"
                      onClick={handleDownloadBrochure}
                      variant="outline"
                    >
                      <IconBookmark width={20} height={20} className="text-primary" />
                      <span className="ms-2">Descargar brochure</span>
                    </Button>
                  </Dropdown.Item>
                )}

                {(property.extra_file && property.extra_file !== "") && (
                  <Dropdown.Item
                    className="d-flex align-items-center px-2 py-2 my-1 rounded-1"
                  >

                    <Button
                      className="property-download px-2 py-0 w-100 text-start"
                      onClick={handleDownloadExtraDocument}
                      variant="outline"
                    >
                      <IconFileText width={20} height={20} className="text-primary" />
                      <span className="ms-2">{translation.extraDocument}</span>
                    </Button>
                  </Dropdown.Item>
                )}

                {/* Si muestra cotizador : */}
                

              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
