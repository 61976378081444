import { PropertyType } from "../../../interfaces/property";
import { CountryFlag } from "../../CountryFlag/CountryFlag";
import { IconFire } from "../../CustomIcons/IconFire";
import { Col, Placeholder, Row } from "react-bootstrap";
import { PropertyTablePrice } from "../PropertyTablePrice/PropertyTablePrice";
import useUser from "../../../hooks/user/useUser";
import useCountry from "../../../hooks/config/useConfig";
import { IconCheckCircleFill } from "../../CustomIcons/IconCheckCircleFill";
import { ProjectType } from "../../../interfaces/project";
import { usePromo } from "../../../hooks/promo/usePromo";
import { IconStarFill } from "../../CustomIcons/IconStarFill";

export const PropertyTableRow = ({ data, showSocialHousing }: { data: ProjectType, showSocialHousing: boolean }) => {
    const { userState } = useUser();
    const isBlocked = data.level > Number(userState.level) ;
    const isUnBlocked = data.level ? data.level <= Number(userState.level) : false;
    const isRent = !!data.units?.filter((p) => p.operation_type === "Alquiler").length;
    const { translation } = useCountry();
    const translatedStatus = translation.constructionStatus.find((t: any) => t.id === data.status?.identifier)
    const {countryPromo} = usePromo()
    const enableBlackFriday = !!countryPromo?.is_black_friday


    return (
        <Row className="p-2">
            <Col className="first-column d-flex justify-content-between px-1 align-items-center flex-wrap">
                <CountryFlag flag={data.country?.flag} shadow border />
                {data.is_trend && (
                    <IconFire width={20} height={20} />
                )}
                
                {!!data.promos && data.promos == 1 && (
                    <div className="hover:text-black d-flex align-items-center py-1 px-2 rounded-1 mb-1 bg-warning">
                    <IconStarFill width={9} height={10} />
                    <span className="ms-1 text-uppercase fw-bold lh-1 fs-xxs d-block">
                        PROMO
                    </span>
                    </div>
                )}
            </Col>
            <Col className="px-1">
                {isBlocked ? (
                    <Placeholder xs={8} className="text-light rounded mb-1" />
                ) : (
                    <p className="fw-bold text-secondary property-table-title lh-sm mb-1">
                        {data.title}
                        {(enableBlackFriday && data.promos && data.promos !== "" && data.promos == 1) &&
                            <img src="https://cdn1.infocasas.com.uy/web/6381001fb3831_etiqueta.png" className="logo-promo" />
                        }
                        {isUnBlocked && (
                            <span className="text-primary d-block"><small>Nivel {data.level}</small></span>
                        )}
                    </p>
                )}
            </Col>
            <Col className="px-1" xs={2} sm={2} md={2} lg={2}>
                <div className="property-location text-secondary">
                    {isBlocked ? (
                        <>
                            <Placeholder xs={8} className="text-light rounded mb-1" />
                            <Placeholder xs={6} className="text-light rounded mb-1" />
                        </>
                    ) : (
                        <>
                            <p className="property-hood fw-bold m-0">
                                {data.zone ? (data.country?.code === "CL" ? `Comuna: ${data.zone.name}, ` : `${data.zone.name}, `) : ''}
                                {data.country?.code === "CL" && "Región: "} {data.department?.name}
                            </p>
                            <p className="property-address m-0">{data.address}</p>
                        </>
                    )}
                </div>
            </Col>
            <Col className="px-1">
                {data.handover_date_text && <span className="tag-hand-over m-0">{data.handover_date_text}</span>}
                <p className="text-secondary m-0 mt-1">Estado: {translatedStatus?.text}</p>
            </Col>
            <Col className="px-1" xs={2} sm={2} md={2} lg={2}>
                <PropertyTablePrice units={data.units} currencyPrice={isRent ? data.rent_currency : data.sale_currency} isBlocked={isBlocked} propertyTypeId={data.type.identifier} />
            </Col>
            <Col className="px-1">
                {isBlocked ? (
                    <Placeholder xs={6} className="text-light rounded mb-1" />
                ) :
                    data.developers.map((developer, index) => (
                        
                        !!developer.developer && (<p className="text-secondary" key={`data-developer-${index}`}>{developer.developer?.name}</p>)
                        
                        
                    ))
                }
            </Col>
            <Col className="px-1" xs={1} sm={1} md={1} lg={1}>
                <span className="tag-commision">{data.financial.commission.percent}%</span>
            </Col>
            {
                showSocialHousing &&
                <Col className="px-1" xs={1} sm={1} md={1} lg={1}>
                    {data.social_housing ? <IconCheckCircleFill width={14} height={14} className="text-secondary" /> : <span className="text-secondary">-</span>}
                </Col>
            }

        </Row>
    )
}